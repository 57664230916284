import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import styles from "./Sidebar.module.css";

import ConnectWalletButton from "../../../ConnectWalletButton";
import NetworkSwitch from "../../../NetworkSwitch";
import { IS_DEVICE_MOBILE } from "../../../../App.Config";
import { setSidebarView } from "../../../../actions/master-actions";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { isSidebarOpen: sidebar, isWalletConnected } = useSelector((state) => state.masterReducer);

  const NAV_LINKS = [
    {
      logo: <i className="bi bi-bar-chart-fill"></i>,
      title: "Staking",
      path: "/staking",
    },
    {
      logo: <i className="bi bi-layers-fill"></i>,
      title: "Farming",
      path: "/farming",
    },
    {
      logo: <i className="bi bi-cash-coin"></i>,
      title: "Vesting",
      path: "/vesting",
    },
  ];

  const handleBodyClick = () => {
    if (IS_DEVICE_MOBILE && sidebar) {
      dispatch(setSidebarView(false));
    }
  };

  return (
    <div id="dashboard-sidebar" className={`${sidebar ? styles.sidebarContainer : styles.sidebarContainerClose}`}>
      <div className={styles.sListDiv}>
        <div className={styles.showElements}>
          <ConnectWalletButton style={{ width: "100%" }} showConnectedAddress={true} />
          {isWalletConnected && <NetworkSwitch style={{ marginTop: 10, width: "100%" }} />}
        </div>
        <ul className={styles.sidebarList}>
          {NAV_LINKS.map((item) => {
            return (
              <NavLink key={item.title} exact activeClassName={styles.activeClass} to={item.path} onClick={handleBodyClick}>
                <li className={styles.sideLi}>
                  {item.logo}
                  <span className="mx-1">{item.title}</span>
                </li>
              </NavLink>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
