import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";

import TokenAbi from "../abi/TokenAbi.json";

export const stakingTokenContract = (contractAddress) => new Contract(contractAddress, new utils.Interface(TokenAbi));

export const totalStakedContractCall = (contractAddress, stakingContractAddress) => ({
  abi: new utils.Interface(TokenAbi),
  address: contractAddress,
  method: "balanceOf",
  args: [stakingContractAddress],
});

export const allowanceContractCall = (contractAddress, walletAddress, stakingContractAddress) => ({
  abi: new utils.Interface(TokenAbi),
  address: contractAddress,
  method: "allowance",
  args: [walletAddress, stakingContractAddress],
});

export const getTokenSymbol = (contractAddress) => ({
  abi: new utils.Interface(TokenAbi),
  address: contractAddress,
  method: "symbol",
});

export const totalStakeTokenByAddress = (contractAddress, walletAddress) => ({
  abi: new utils.Interface(TokenAbi),
  address: contractAddress,
  method: "balanceOf",
  args: [walletAddress],
});

export const getTokenDecimals = (contractAddress) => ({
  abi: new utils.Interface(TokenAbi),
  address: contractAddress,
  method: "decimals",
});

export const approveAllowanceFunction = "approve";
