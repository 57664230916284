import { Contract } from "@ethersproject/contracts";
import { utils } from "ethers";

import StakingAbi from "../abi/StakingAbi.json";

export const stakingContractAbiInterface = new utils.Interface(StakingAbi);

export const stakingContract = (address) => new Contract(address, stakingContractAbiInterface);

export const totalStakersContractCall = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "getNumberOfHolders",
});

export const getPendingDivsContractCall = (contractAddress, walletAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "getPendingDivs",
  args: [walletAddress],
});

export const depositedTokenContractCall = (contractAddress, walletAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "depositedTokens",
  args: [walletAddress],
});

export const getStakersListContractCall = (contractAddress, startIndex, endIndex) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "getStakersList",
  args: [startIndex, endIndex],
});

export const depositedTokenAddressContractCall = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "depositToken",
});

export const rewardTokenAddressContractCall = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "rewardToken",
});

export const totalEarnedTokensContractCall = (contractAddress, walletAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "totalEarnedTokens",
  args: [walletAddress],
});

export const rewardRateContractCall = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "rewardRate",
});

export const rewardIntervalContractCall = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "rewardInterval",
});

export const getPoolSize = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "hardcap",
});

export const getTotalStaked = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "totalStaked",
});

export const getMinAmount = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "minAmount",
});

export const getEarlyWithdrawal = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "isEarlywithdrawAvailable",
});

export const getPoolMaturityTime = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "poolMaturityTime",
});

export const depositStakingFunction = "deposit";
export const withdrawStakingFunction = "withdraw";
export const harvestStakingFunction = "claimDivs";
