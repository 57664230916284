import React, { useState } from "react";
import { toast } from "react-toastify";
import { utils } from "ethers";

import styles from "./StakingCardV2.module.css";
import tokenIcon from "../../../assets/images/metria-favicon.png";

import TokenIcon from "../../common/TokenIcon";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { toMax2Decimals, toMax4Decimals } from "./utils";
import { Collapse } from "reactstrap";

const Progress = ({ done }) => {
  return (
    <div className={styles.progress} style={{ position: "relative" }}>
      <div
        className={styles.progressDone}
        style={{
          opacity: 1,
          width: `${done !== 0 ? done + "%" : ""}`,
        }}
      ></div>
      <div style={{ color: "#fff", textAlign: "center", position: "absolute", left: "40%", top: 0 }}>{done !== 0 ? done + "%" : ""}</div>
    </div>
  );
};

function StakingCardV2({
  tokenName,
  stakeAmount,
  // aprValue,
  poolSize,
  totalStakedInPool,
  walletBalance,
  poolMaturityTime,
  updateWalletAmount,
  checkAndStakeToken,
  checkAndHarvestToken,
  checkAndUnstake,
  walletAmount,
  totalEarned = 0,
  totalPending = 0,
  rewardTokenPriceUSD,
  buyUrl = "",
}) {
  const [isStakeSelected, setIsStakeSelected] = useState(true);

  const onStakeToggle = (isStake) => {
    setIsStakeSelected(isStake);
    updateWalletAmount("");
  };

  const setMaxStakeAmount = () => {
    updateWalletAmount(walletBalance);
  };

  const setMaxUnstakeAmount = () => {
    updateWalletAmount(stakeAmount);
  };

  const [isRulesOpen, setRulesOpen] = useState(false);

  const rulesToggle = (e) => {
    setRulesOpen(e);
  };

  const openInNewWindow = (url) => {
    const newWindow = window.open(url);
  };

  const remainsTokenToStake = poolSize - totalStakedInPool;
  const getMaturityTime = JSON.parse(poolMaturityTime);

  const maxAmountHandler = () => {
    updateWalletAmount(isStakeSelected ? remainsTokenToStake : stakeAmount);
  };

  const depositWithdrewHandler = (event) => {
    updateWalletAmount(0);
    setIsStakeSelected(isStakeSelected ? false : true);
  };

  function millionConversion(amount) {
    // Nine Zeroes for Billions
    return Math.abs(Number(amount)) >= 1.0e9
      ? Math.abs(Number(amount)).toFixed(3) / 1.0e9 + "b"
      : // Six Zeroes for Millions
      Math.abs(Number(amount)) >= 1.0e6
      ? Math.abs(Number(amount)).toFixed(3) / 1.0e6 + "m"
      : // Three Zeroes for Thousands
      Math.abs(Number(amount)) >= 1.0e3
      ? Math.abs(Number(amount)).toFixed(3) / 1.0e3 + "k"
      : Math.abs(Number(amount)).toFixed(3);
  }

  return (
    <>
      <div className={styles.stakingCard + " card"}>
        <div className="container">
          <div className="row">
            <div className="col-12 mt-2">
              <div className="d-inline">
                <TokenIcon image={tokenIcon} />
              </div>
              <p className={styles.displayTokenName}>STAKE {tokenName}</p>
            </div>
            <hr className={styles.hrTag} />
          </div>
          <div className="row justify-content-between">
            <div className="col-6 mt-3">
              <div className={styles.tokenInfo + " text-center"}>
                {/* <p className="mb-0">APR</p>
                <p style={{ color: "#5B46F9" }}>{aprValue ? aprValue : 0.0}%</p> */}
                <p className="mb-0">APY</p>
                <p style={{ color: "#5B46F9" }}>65%</p>
              </div>
            </div>
            <div className="col-6 mt-3">
              <div className={styles.tokenInfo + " text-center"}>
                <p className="mb-0">{tokenName} Staked</p>
                <p style={{ color: "#5B46F9" }}>{stakeAmount ? stakeAmount : "0.0"}</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-6 mt-3">
              <div className={styles.tokenInfo + " text-center"}>
                <p className="mb-0">Maturity Date</p>
                <p style={{ color: "#5B46F9" }}>
                  {getMaturityTime?.days > 0 ? getMaturityTime?.days + "d " : ""}
                  {getMaturityTime?.hours >= 0 ? getMaturityTime?.hours + "h" : "0h"}
                </p>
              </div>
            </div>
            <div className="col-6 mt-3">
              <div className={styles.tokenInfo + " text-center"}>
                <p className="mb-0">Pool Size</p>
                <p style={{ color: "#5B46F9" }}>
                  {totalStakedInPool}/{poolSize}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="container-fluid">
            <div className="row">
              <div className={"col-12 text-center p-0 m-0"}>
                <div className={styles.LogicContent}>
                  <div className={styles.flexRow}>
                    <div
                      className={`${isStakeSelected ? styles.activeStakeFlex : styles.stakeFlex}`}
                      onClick={() => {
                        onStakeToggle(true);
                      }}
                    >
                      STAKE
                    </div>
                    <div
                      className={`${!isStakeSelected ? styles.activeUnstakeFlex : styles.unstakeFlex}`}
                      onClick={() => {
                        onStakeToggle(false);
                      }}
                    >
                      UNSTAKE
                    </div>
                  </div>
                  <div className={styles.cardContent + " p-4"}>
                    <div className={`${!isStakeSelected ? "" : styles.unStake}` + " mt-lg-3 mt-2"}>
                      <div className="row justify-content-around">
                        <p className="mb-1 mt-1 text-start" style={{ color: "#ffff" }}>
                          Total {tokenName} staked:&nbsp;{toMax4Decimals(parseFloat(stakeAmount))}
                        </p>
                        <div className="col-8 col-sm-9">
                          <input
                            type="text"
                            placeholder="Enter Amount"
                            className={styles.InputAmount}
                            value={walletAmount}
                            onChange={(e) => updateWalletAmount(e.target.value)}
                          />
                        </div>
                        <div className="col-4 col-sm-3">
                          <button className={styles.MaxButton} onClick={() => setMaxUnstakeAmount()}>
                            Max
                          </button>
                        </div>
                      </div>
                      <div className="row mt-3 justify-content-around">
                        <div className="col-12">
                          <button
                            className={styles.WithdrawBTN}
                            onClick={() => {
                              checkAndUnstake();
                            }}
                          >
                            Withdraw
                          </button>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className={styles.userTokenInfo + " col-6"}>
                          <div className={styles.subHeaderLight}>{tokenName} EARNED</div>
                          <div>{totalEarned}</div>
                          <div>~ {Number(totalEarned) ? (Number(totalEarned) * rewardTokenPriceUSD).toFixed(11) : 0.0} USD</div>
                        </div>
                        <div className={styles.userTokenInfo + " col-6"}>
                          <div className={styles.subHeaderLight}>{tokenName} PENDING</div>
                          <div>{totalPending}</div>
                          <div>~ {Number(totalPending) ? (Number(totalPending) * rewardTokenPriceUSD).toFixed(11) : 0.0} USD</div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-8">
                          <ConfirmationModal
                            message={"Are you sure you want to claim pending tokens?"}
                            style={{ minWidth: "100px" }}
                            onConfirm={() => {
                              if (Number(totalPending) !== 0) {
                                checkAndHarvestToken();
                              } else {
                                toast.error("Pending Amount is Null");
                              }
                            }}
                          >
                            <button className={styles.HarvestBTN}>Harvest</button>
                          </ConfirmationModal>
                        </div>
                        <div className="col-4 p-1">
                          <button className={styles.RulesBTN} onClick={() => rulesToggle(!isRulesOpen)}>
                            Rules {isRulesOpen ? <i className="bi bi-chevron-up" /> : <i className="bi bi-chevron-down" />}
                          </button>
                        </div>
                        <div className="col-12 " style={{ color: "#fff" }}>
                          <Collapse isOpen={isRulesOpen}>
                            <ol className=" mt-3 text-start">
                              <li>It is a 1 year fixed staking period with 65% APY.</li>
                              <li>All rewards will be given in {tokenName} tokens and users can easily claim those rewards or harvest it.</li>
                              <li>Users reward are available instantaneously at any time.</li>
                            </ol>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                    <div id="stake" className={`${isStakeSelected ? "" : styles.stake}` + " mt-lg-3 mt-2"}>
                      <div className="row justify-content-around">
                        <p className="mb-1 mt-1 text-start" style={{ color: "#ffff" }}>
                          Wallet {tokenName} balance: {utils.commify(toMax2Decimals(parseFloat(walletBalance)))}
                        </p>
                        <div className="col-8 col-sm-9">
                          <input
                            type="text"
                            placeholder="Enter Amount"
                            className={styles.InputAmount}
                            value={walletAmount}
                            onChange={(e) => updateWalletAmount(e.target.value)}
                          />
                        </div>
                        <div className="col-4 col-sm-3">
                          <button className={styles.MaxButton} onClick={() => setMaxStakeAmount()}>
                            Max
                          </button>
                        </div>
                      </div>
                      <div className="row justify-content-around mt-4">
                        <div className="col-12 ">
                          <button
                            className={styles.DepositeBTN}
                            onClick={() => {
                              checkAndStakeToken();
                            }}
                          >
                            Deposit
                          </button>
                        </div>
                        <div className="col-8 mt-4">
                          <button className={styles.BuyforwardBTN} onClick={() => openInNewWindow(buyUrl)}>
                            Buy {tokenName}
                          </button>
                        </div>
                        <div className="col-4 mt-4 p-1">
                          <button className={styles.RulesBTN} onClick={() => rulesToggle(!isRulesOpen)}>
                            Rules {isRulesOpen ? <i className="bi bi-chevron-up" /> : <i className="bi bi-chevron-down" />}
                          </button>
                        </div>
                        <div className="col-12 " style={{ color: "#fff" }}>
                          <Collapse isOpen={isRulesOpen}>
                            <ol className=" mt-3 text-start">
                              <li>It is a 1 year fixed staking period with 65% APY.</li>
                              <li>All rewards will be given in {tokenName} tokens and users can easily claim those rewards or harvest it.</li>
                              <li>Users reward are available instantaneously at any time.</li>
                            </ol>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StakingCardV2;
