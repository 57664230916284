import { useEthers } from "@usedapp/core";

import styles from "./StakingList.module.css";

import Staking from "../Staking";
import NetworkError from "../../components/NetworkError";

import { CONTRACT_ADDRESS, VALID_APP_NETWORK } from "../../App.Config";
import StakingV2 from "../StakingV2";
import StakingV3 from "../StakingV3";

const StakingList = () => {
  const { chainId } = useEthers();

  return (
    <div className={styles.viewContainer}>
      {VALID_APP_NETWORK.includes(chainId) ? (
        CONTRACT_ADDRESS.STAKING[chainId].map((item) => {
          if (item.version === 1) {
            return (
              <Staking
                tokenPriceUSD={item.TOKEN_PRICE_USD}
                rewardTokenPriceUSD={item.REWARD_TOKEN_PRICE_USD}
                stakingContractAddress={item.CONTRACT}
                stakingTokenContractAddress={item.TOKEN}
                tokenDisplayName={item.TOKEN_NAME}
                tokenBuyURL={item.BUY_URL}
              />
            );
          } else if (item.version === 2) {
            return (
              <StakingV2
                stakingContractAddress={item.CONTRACT}
                tokenPriceUSD={item.TOKEN_PRICE_USD}
                rewardTokenPriceUSD={item.REWARD_TOKEN_PRICE_USD}
                tokenDisplayName={item.TOKEN_NAME}
                tokenBuyURL={item.BUY_URL}
              />
            );
          } else if (item.version === 3) {
            return (
              <StakingV3
                stakingContractAddress={item.CONTRACT}
                tokenPriceUSD={item.TOKEN_PRICE_USD}
                rewardTokenPriceUSD={item.REWARD_TOKEN_PRICE_USD}
                tokenDisplayName={item.TOKEN_NAME}
                tokenBuyURL={item.BUY_URL}
                stakingTokenContractAddress={item.TOKEN}
              />
            );
          }
        })
      ) : (
        <NetworkError />
      )}
    </div>
  );
};

export default StakingList;
