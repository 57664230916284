import React, { useState, useEffect } from "react";
import { useEthers, useTokenBalance, useContractCalls } from "@usedapp/core";
import { utils, BigNumber } from "ethers";
import { toast } from "react-toastify";

import icon from "../../assets/images/metria-favicon.png";

import { useUtilContractFunction, useContractValueTransformation } from "../../hooks/useDappUtility";
import {
  stakingContract,
  totalStakersContractCall,
  userInfoContractCall,
  getPendingDivsContractCall,
  depositStakingFunction,
  withdrawStakingFunction,
  getRewardPerBlock,
  getPoolEndTime,
  getStakedToken,
  getRewardToken,
} from "./services/StakingContractService";
import {
  stakingTokenContract,
  totalStakedContractCall,
  allowanceContractCall,
  approveAllowanceFunction,
  getTokenSymbol,
  getTokenDecimals,
} from "./services/TokenContractService";
import StakingCard from "../../components/cards/StakingCard";
import { CURRENT_CHAIN_BLOCK_TIME } from "../../App.Config";

const Staking = ({
  tokenPriceUSD = 50,
  rewardTokenPriceUSD = 50,
  stakingContractAddress,
  stakingTokenContractAddress,
  tokenDisplayName,
  tokenBuyURL,
}) => {
  const { chainId, account } = useEthers();
  const [aprValue, setAprValue] = useState(0);
  const [inputAmount, setInputAmount] = useState("");

  const { BLOCK_TIME } = CURRENT_CHAIN_BLOCK_TIME[chainId] ?? 3;

  const userBalance = useTokenBalance(stakingTokenContractAddress, account);

  const [totalStakersCount, userInfo, pendingReward, rewardPerBlock, poolEndTime, stakedToken, rewardToken] = useContractCalls([
    totalStakersContractCall(stakingContractAddress),
    userInfoContractCall(stakingContractAddress, account),
    getPendingDivsContractCall(stakingContractAddress, account),
    getRewardPerBlock(stakingContractAddress),
    getPoolEndTime(stakingContractAddress),
    getStakedToken(stakingContractAddress),
    getRewardToken(stakingContractAddress),
  ]);

  const [totalStakedofContract, getAllowance, stakingTokenSymbol, tokenDecimal] = useContractCalls([
    totalStakedContractCall(stakingTokenContractAddress, stakingContractAddress),
    allowanceContractCall(stakingTokenContractAddress, account, stakingContractAddress),
    getTokenSymbol(stakingTokenContractAddress),
    getTokenDecimals(stakingTokenContractAddress),
  ]);

  let contract;
  let tokenContract;
  if (stakingContractAddress) {
    contract = stakingContract(stakingContractAddress);
    tokenContract = stakingTokenContract(stakingTokenContractAddress);
  }
  const setApproveAllowances = useUtilContractFunction(tokenContract, approveAllowanceFunction);
  const depositToken = useUtilContractFunction(contract, depositStakingFunction);
  const withdrawToken = useUtilContractFunction(contract, withdrawStakingFunction);
  const harvestToken = useUtilContractFunction(contract, depositStakingFunction);

  const displayState = useContractValueTransformation(
    {
      totalStaked: totalStakedofContract,
      totalEarned: userInfo,
      stakeAmount: userInfo,
      totalStaker: totalStakersCount,
      pendingReward: pendingReward,
      allowance: getAllowance,
      walletBalance: userBalance,
      rewardPerBlock: rewardPerBlock,
      userLastDepositTime: userInfo,
      poolEndTime: poolEndTime,
      stakingTokenSymbol: stakingTokenSymbol,
      stakedToken: stakedToken,
      rewardToken: rewardToken,
    },
    {
      totalStaked: (val) => (val ? utils.formatUnits(val[0]._hex, tokenDecimal?.[0]) : 0),
      totalEarned: (val) => (val ? utils.formatUnits(val[2]._hex, tokenDecimal?.[0]) : 0),
      stakeAmount: (val) => (val ? utils.formatUnits(val[0]._hex, tokenDecimal?.[0]) : 0),
      totalStaker: (val) => (val ? parseInt(val) : 0),
      pendingReward: (val) => (val ? utils.formatUnits(val[0]._hex, tokenDecimal?.[0]) : 0),
      allowance: (val) => (val ? utils.formatUnits(val[0]._hex, "ether") : 0),
      walletBalance: (val) => (val ? utils.formatEther(val) : 0),
      rewardPerBlock: (val) => (val ? Number(utils.formatUnits(val[0]._hex, "ether")) : 0),
      userLastDepositTime: (val) => (val ? parseInt(utils.formatUnits(val[4]._hex, 0)) : 0),
      poolEndTime: (val) => (val ? parseInt(utils.formatUnits(val[0]._hex, 0)) : 0),
      stakingTokenSymbol: (val) => (val ? val[0] : ""),
      stakedToken: (val) => (val ? val[0] : ""),
      rewardToken: (val) => (val ? val[0] : ""),
    }
  );

  const handleInputValueChange = (inputAmount) => {
    if (isNaN(inputAmount)) {
      return;
    }
    if (Number(inputAmount) < 0) {
      return;
    }
    setInputAmount(inputAmount.trim());
  };

  const checkAndStakeToken = (onValidCallback) => {
    if (Number(inputAmount) <= Number(displayState.walletBalance) && Number(inputAmount) > 0) {
      if (!(parseFloat(displayState.allowance) > 0 && parseFloat(displayState.allowance) > Number(inputAmount))) {
        setApproveAllowances.send(stakingContractAddress, BigNumber.from(2).pow(256).sub(1));
      } else {
        depositToken.send(utils.parseUnits(inputAmount, tokenDecimal?.[0]));
        onValidCallback && onValidCallback();
      }
    } else {
      toast.error("Not enough tokens");
    }
  };

  useEffect(() => {
    if (setApproveAllowances.state && setApproveAllowances.state.status === "Success") {
      depositToken.send(utils.parseUnits(inputAmount, tokenDecimal?.[0]));
    }
  }, [setApproveAllowances.state]);

  useEffect(() => {
    calculateApr();
  }, [displayState.totalStaked, displayState.rewardPerBlock]);

  const calculateApr = () => {
    const valueOfRewardPerBlock = displayState.rewardPerBlock;
    const blocksPerYear = (60 / BLOCK_TIME) * 60 * 24 * 365;
    const rewardEveryBlock = displayState.rewardPerBlock ? valueOfRewardPerBlock : 0; // e.g 0.000000000047564688
    const totalRewardPricePerYear = rewardTokenPriceUSD * rewardEveryBlock * blocksPerYear;
    const totalStakingTokenInPool = tokenPriceUSD * displayState.totalStaked;
    const apr = totalStakingTokenInPool ? (totalRewardPricePerYear / totalStakingTokenInPool) * 100 : (totalRewardPricePerYear / tokenPriceUSD) * 100;
    setAprValue(Number(apr).toFixed(3));
  };

  const checkAndHarvestToken = () => {
    if (Number(displayState.pendingReward) > 0) {
      harvestToken.send(0);
    }
  };

  const checkAndUnstake = (onValidCallback) => {
    if (Number(displayState.stakeAmount) > 0 && Number(displayState.stakeAmount) >= Number(inputAmount)) {
      withdrawToken.send(utils.parseUnits(inputAmount, tokenDecimal?.[0]));
      onValidCallback && onValidCallback();
    } else {
      toast.error("Not enough tokens");
    }
  };

  useEffect(() => {
    if (depositToken.state.status === "Success" || withdrawToken.state.status === "Success") {
      handleInputValueChange("");
    }
  }, [depositToken.state, withdrawToken.state]);

  return (
    <StakingCard
      tokenName={tokenDisplayName}
      tokenIcon={icon}
      aprValue={aprValue}
      totalEarned={displayState.totalEarned}
      totalPending={displayState.pendingReward}
      stakeAmount={displayState.stakeAmount}
      updateWalletAmount={handleInputValueChange}
      checkAndStakeToken={checkAndStakeToken}
      buyUrl={tokenBuyURL}
      walletBalance={displayState.walletBalance}
      walletAmount={inputAmount}
      checkAndHarvestToken={checkAndHarvestToken}
      checkAndUnstake={checkAndUnstake}
      rewardTokenPriceUSD={rewardTokenPriceUSD}
    />
  );
};

export default Staking;
