import React, { useState, useEffect } from "react";
import { useEthers, useTokenBalance, useTokenAllowance, useContractCalls } from "@usedapp/core";
import { toast } from "react-toastify";
import { utils, BigNumber } from "ethers";

import {
  totalStakersContractCall,
  getPendingDivsContractCall,
  depositedTokenContractCall,
  depositedTokenAddressContractCall,
  rewardTokenAddressContractCall,
  totalEarnedTokensContractCall,
  rewardRateContractCall,
  rewardIntervalContractCall,
  getPoolSize,
  getTotalStaked,
  getMinAmount,
  getEarlyWithdrawal,
  getPoolMaturityTime,
  depositStakingFunction,
  harvestStakingFunction,
  withdrawStakingFunction,
  stakingContract,
} from "./services/StakingContractService";
import { approveAllowanceFunction, tokenContract } from "./services/TokenContractService";
import { useContractValueTransformation, useUtilContractFunction } from "../../hooks/useDappUtility";
import StakingCardV3 from "../../components/cards/StakingCardV3";

const StakingV3 = ({ stakingContractAddress, tokenBuyURL, tokenPriceUSD, rewardTokenPriceUSD, tokenDisplayName }) => {
  const { account } = useEthers();

  const stakingTokenContract = stakingContractAddress && stakingContract(stakingContractAddress);

  const [inputAmount, setInputAmount] = useState("");

  function getTimeRemaining(endTime) {
    const total = Date.parse(endTime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  const [pendingDives, depositedTokens, totalStaked, depositTokenAddress, totalEarned, rewardRate, rewardInterval, poolSize] = useContractCalls([
    getPendingDivsContractCall(stakingContractAddress, account),
    depositedTokenContractCall(stakingContractAddress, account),
    totalStakersContractCall(stakingContractAddress),
    depositedTokenAddressContractCall(stakingContractAddress),
    totalEarnedTokensContractCall(stakingContractAddress, account),
    rewardRateContractCall(stakingContractAddress),
    rewardIntervalContractCall(stakingContractAddress),
    getPoolSize(stakingContractAddress)
  ]);

  const displayState = useContractValueTransformation(
    {
      stakingContractAddress: stakingContractAddress,
      pendingDives: pendingDives,
      stakeAmount: depositedTokens,
      totalStakers: totalStaked,
      depositTokenAddress: depositTokenAddress,
      totalEarnedToken: totalEarned,
      rewardRate: rewardRate,
      rewardInterval: rewardInterval,
      poolSize: poolSize
    },
    {
      stakingContractAddress: (val) => val,
      pendingDives: (val) => (val ? Number(utils.formatUnits(val[0], 18)).toFixed(3) : 0),
      stakeAmount: (val) => (val ? Number(utils.formatUnits(val[0], 18)).toFixed(3) : 0),
      totalStakers: (val) => (val ? Number(val[0]) : 0),
      depositTokenAddress: (val) => (val ? Object.values(val)[0] : ""),
      totalEarnedToken: (val) => (val ? Number(utils.formatUnits(val[0], 18)).toFixed(3) : 0),
      rewardRate: (val) => (Number(val) / 100).toFixed(1),
      rewardInterval: (val) => Number(val) / 60,
      poolSize: (val) => (val ? Number(utils.formatUnits(val[0], 18)).toFixed(3) : 0),
    }
    );

  const tokenBalance = useTokenBalance(displayState.depositTokenAddress, account);
  const contractBalance = useTokenBalance(displayState.depositTokenAddress, stakingContractAddress);
  const tokenAllowance = useTokenAllowance(displayState.depositTokenAddress, account, stakingContractAddress);

  const tokenDisplayState = useContractValueTransformation(
    {
      balance: tokenBalance,
      allowance: tokenAllowance,
      contractBalance: contractBalance,
    },
    {
      balance: (val) => (val ? Number(utils.formatUnits(val, 18)).toFixed(3) : 0),
      allowance: (val) => (val ? utils.formatUnits(val, 18) : 0),
      contractBalance: (val) => (val ? Number(utils.formatUnits(val, 18)).toFixed(3) : 0),
    }
  );

  const ERC20Contract = displayState.depositTokenAddress && tokenContract(displayState.depositTokenAddress);

  const approveAllowances = useUtilContractFunction(ERC20Contract, approveAllowanceFunction);
  const depositToken = useUtilContractFunction(stakingTokenContract, depositStakingFunction);
  const harvestToken = useUtilContractFunction(stakingTokenContract, harvestStakingFunction);
  const withdrawToken = useUtilContractFunction(stakingTokenContract, withdrawStakingFunction);

  useEffect(() => {
    if (approveAllowances.state.status === "Success") {
      depositToken.send(utils.parseUnits(inputAmount, 18));
    }
  }, [approveAllowances.state]);

  const handleInputValueChange = (inputAmount) => {
    if (isNaN(inputAmount)) {
      return;
    }
    setInputAmount(inputAmount);
  };

  const checkAndStakeToken = () => {
    if (Number(inputAmount) <= Number(tokenDisplayState.balance)) {
      if (!Number(inputAmount)) {
        toast.error("Amount should not be null");
      } else if (Number(tokenDisplayState.allowance) > 0 && Number(tokenDisplayState.allowance) >= Number(inputAmount)) {
        depositToken.send(utils.parseUnits(inputAmount, 18));
      } else {
        approveAllowances.send(stakingContractAddress, BigNumber.from(2).pow(256).sub(1));
      }
    } else {
      toast.error("Insufficient balance");
    }
  };

  const checkAndHarvestToken = () => {
    harvestToken.send();
  };

  const checkAndUnstake = () => {
    if (!Number(inputAmount)) {
      toast.error("Amount should not be null");
      return;
    } else if (Number(inputAmount) > Number(displayState.stakeAmount)) {
      toast.error("Insufficient balance");
      return;
    }
    withdrawToken.send(utils.parseUnits(inputAmount.toString(), 18));
  };

  return (
    <StakingCardV3
      rewardTokenPriceUSD={rewardTokenPriceUSD}
      tokenName={tokenDisplayName}
      aprValue={displayState.rewardRate}
      totalEarned={displayState.totalEarnedToken}
      totalStakers={displayState.totalStakers}
      totalPending={displayState.pendingDives}
      stakeAmount={displayState.stakeAmount}
      poolSize={displayState.poolSize}
      updateWalletAmount={handleInputValueChange}
      checkAndStakeToken={checkAndStakeToken}
      buyUrl={tokenBuyURL}
      walletBalance={tokenDisplayState.balance}
      walletAmount={inputAmount}
      checkAndHarvestToken={checkAndHarvestToken}
      checkAndUnstake={checkAndUnstake}
      tokenPriceUSD={tokenPriceUSD}
      contractBalance={tokenDisplayState.contractBalance}
    />
  );
};

export default StakingV3;
