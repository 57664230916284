import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

import DashboardLayout from "./components/layouts/DashboardLayout";
import LoaderComponent from "./components/common/Loader";

import StakingList from "./views/StakingList";
import Farming from "./views/Farming";
import ConnectWallet from "./views/ConnectWallet";
import Vesting from "./views/Vesting";

function App() {
  const { isWalletConnected } = useSelector((state) => state.masterReducer);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LoaderComponent />
      <Switch>
        <Route path="*">
          <DashboardLayout>
            <Switch>
              <Route exact path="/">
                <ConnectWallet />
              </Route>

              {isWalletConnected && <Route exact path="/staking" component={StakingList} />}
              {isWalletConnected && <Route exact path="/farming" component={Farming} />}
              {isWalletConnected && <Route exact path="/vesting" component={Vesting} />}

              <Route path="*" component={ConnectWallet} />
            </Switch>
          </DashboardLayout>
        </Route>
      </Switch>
    </>
  );
}

export default App;
