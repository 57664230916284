import { Contract } from "@ethersproject/contracts";
import { utils } from "ethers";

import StakingAbi from "../abi/StakingAbi.json";

export const stakingContractAbiInterface = new utils.Interface(StakingAbi);

export const stakingContract = (contractAddress) => new Contract(contractAddress, stakingContractAbiInterface);

export const totalStakersContractCall = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "getNumberOfStakers",
});

export const userInfoContractCall = (contractAddress, walletAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "userInfo",
  args: [walletAddress],
});

export const getPendingDivsContractCall = (contractAddress, walletAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "pendingReward",
  args: [walletAddress],
});

export const getStakersListContractCall = (contractAddress, startIndex, endIndex) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "getStakersList",
  args: [startIndex, endIndex],
});

export const getRewardPerBlock = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "rewardPerBlock",
});

export const getPoolEndTime = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "poolEndTime",
});

export const getStakedToken = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "stakedToken",
});

export const getRewardToken = (contractAddress) => ({
  abi: stakingContractAbiInterface,
  address: contractAddress,
  method: "rewardToken",
});

export const depositStakingFunction = "deposit";
export const withdrawStakingFunction = "withdraw";
