export const VALID_APP_NETWORK = [56, 1, 137/* , 4 */];
export const DEFAULT_NETWORK_ERROR = "Switch to Ethereum, Binance or Polygon network";

export const CURRENT_CHAIN_BLOCK_TIME = {
  1: { BLOCK_TIME: 15 },
  56: { BLOCK_TIME: 3 },
  137: { BLOCK_TIME: 2 },
  // 4: { BLOCK_TIME: 15 },
};

export const CONTRACT_ADDRESS = {
  FARMING: {
    1: {
      CONTRACT: "0xa57328bda040653e8F240408D29Cc41cea7Be1c9",
      VALID_NETWORK: true,
    },
    56: {
      CONTRACT: "0xDf89d467B37f1B9527Ce9a4b3ACC61C050cb1325",
      VALID_NETWORK: true,
    },
    137: {
      CONTRACT: "0x4304822B2b800B793CD6ef158864389271260181",
      VALID_NETWORK: true,
    },
    // 4: {
    //   CONTRACT: "0xA88C5Fb8B018481E663CA20ABE1296Aa9958942c",
    //   VALID_NETWORK: true,
    // },
    // 97: {
    //   CONTRACT: "0x576bd727e26C83114CFc41B0DCe9353fDc74464E",
    //   VALID_NETWORK: true,
    // },
    // 80001: {
    //   CONTRACT: "0x350B9Ab77cBE75fe6000A2599A2F39A117e57D86",
    //   VALID_NETWORK: true,
    // },
  },
  STAKING: {
    1: [
      {
        version: 1,
        CONTRACT: "0x568e1630B271415ed1934C608995Ce94DE79e9F7",
        TOKEN: "0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
        TOKEN_NAME: "METR",
        BUY_URL: "https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
        TOKEN_PRICE_USD: 50,
        REWARD_TOKEN_PRICE_USD: 50,
      },
      {
        version: 3,
        CONTRACT: "0x0bD1dcC84A9839385fAf957234e29b588F118391",
        TOKEN: "0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
        TOKEN_NAME: "METR",
        BUY_URL: "https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
        TOKEN_PRICE_USD: 50,
        REWARD_TOKEN_PRICE_USD: 50,
      },
    ],
    56: [
      {
        version: 1,
        CONTRACT: "0x3ced366401424e704a3a5510d4f2ae33b2914da2",
        TOKEN: "0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
        TOKEN_NAME: "METR",
        BUY_URL: "https://pancakeswap.finance/swap?outputCurrency=0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
        TOKEN_PRICE_USD: 50,
        REWARD_TOKEN_PRICE_USD: 50,
      },
      {
        version: 3,
        CONTRACT: "0x0b347a7801F8943E757aFBCe00A9668520d294c2",
        TOKEN: "0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
        TOKEN_NAME: "METR",
        BUY_URL: "https://pancakeswap.finance/swap?outputCurrency=0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
        TOKEN_PRICE_USD: 50,
        REWARD_TOKEN_PRICE_USD: 50,
      },
    ],
    137: [
      {
        version: 1,
        CONTRACT: "0xB94E3150d98633dA1c3eC0c64e4E7f7E1A2e5DA3",
        TOKEN: "0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
        TOKEN_NAME: "METR",
        BUY_URL: "https://quickswap.exchange/#/swap?outputCurrency=0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
        TOKEN_PRICE_USD: 50,
        REWARD_TOKEN_PRICE_USD: 50,
      },
      {
        version: 3,
        CONTRACT: "0xA5Db30E532e9A13CFf86D363124166Ba6502e71c",
        TOKEN: "0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
        TOKEN_NAME: "METR",
        BUY_URL: "https://quickswap.exchange/#/swap?outputCurrency=0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
        TOKEN_PRICE_USD: 50,
        REWARD_TOKEN_PRICE_USD: 50,
      },
    ],
    // 4: [
    //   // {
    //   //   version: 2,
    //   //   CONTRACT: "0xBecC5B90D3dFF79Ae8659F1745cCe5d3F4e17f5a",
    //   //   TOKEN: "0x2605B1574c5644a870A0A6cbb664d7d000D396Ba",
    //   //   TOKEN_NAME: "METR",
    //   //   BUY_URL: "https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
    //   //   TOKEN_PRICE_USD: 0.005,
    //   //   REWARD_TOKEN_PRICE_USD: 0.005,
    //   // },
    //   {
    //     version: 3,
    //     CONTRACT: "0x771d5c5ea701fd0FcD238b11176Ac16Ca17281d3",
    //     TOKEN: "0x2605B1574c5644a870A0A6cbb664d7d000D396Ba",
    //     TOKEN_NAME: "METR",
    //     BUY_URL: "https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
    //     TOKEN_PRICE_USD: 0.005,
    //     REWARD_TOKEN_PRICE_USD: 0.005,
    //   },
    // ],
  },
};

export const VESTING = "Vesting";
export const SALE = "Sale";

export const SALE_DETAILS = {
  1: {
    Network: 1,
    NetworkName: "Ethereum",
    ProjectName: "Metria",
    ProjectStatus: VESTING, //SALE
    description:
      "Metria is a multichain platform that offers a well-chosen set of features and enhancements transforming the blockchain space from “something new” to an imperative, high-growth industry.",
    note: "The users will be allocated the tokens in their preferred blockchain",
    WhitepaperUrl: "https://metrianetwork.io/documents/Metria-network---Whitepaper-compressed.pdf",
    WebsiteUrl: "https://metrianetwork.io/",
    SalePrice: "$50",
    VestingAddress: "0x44D2aa0dB9906427A131cDF796dD832b8404639E",
    SaleAddress: "0x6f84706cdf580df92e541d8637984b21c2c60da2",
    //privatesale : "0xe0B74b6E8b3B75714a1Bd24784172814A48411E0",
    //presale round 2: "0xe94DdFefbDF03a11DA229Af3db102407c9976a45",
    //presale round 1: "0x6f84706cdf580df92e541d8637984b21c2c60da2",
    TokenName: "METRIA",
    TokenSymbol: "METR",
    TokenAddress: "0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0", //"0x13aABFc2F076D93F8226FDf610C5052B127cAB67",
    TokenNetworkURL: "https://etherscan.io/address/0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0",
    DepositTokenName: "USDT",
    DepositTokenSymbol: "USDT",
    DepositTokenDecimal: 6,
    DepositTokenAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    DepositTokenNetworkURL: "https://etherscan.io/address/0xdac17f958d2ee523a2206206994597c13d831ec7",
    MarketCap: "5,50,000",
    SalePeriod: "Wed Mar 30 2022 16:22:03 GMT+0530 (India Standard Time)",
    OnChainWhiteList: false,
    OffChainWhiteList: false,
    OnlyWhiteListed: false,
    WhitelistUsers: [
      "0x772D81F65eF86C39765D07FC7Aa8258dE148c80f",
      "0x50E41246cE4b060593D30A3183e8D4a5a45919d7",
      "0Xb5b4c77E122F1aC8F942acC8C44c2aBEAD7c9468",
      "0xC19C49Fae471D37eF816468cEF915815C55cdac3",
      "0x2c16eF682cd9d7bf3d97657b2733E82084de462f",
      "0x8b6a88f97FDaba954aFeDFF74f4632b71c823560",
      "0x8a810DA3df4846616A08ef06472811FbAffcfaA8",
      "0xc850723E3Acd2ceA7A14085E67Eb94022DDDd363",
      "0x6DD66582100DFB86f00D79bca235CebF29CAB3c8",
      "0xd77958692fd08Fe82d7B142B9D23E0Ac4815b70c",
      "0x1Ba2137065e47a39cb770af036B3A218a48a69Ff",
      "0x1a696BaC10616398fb5Ac1d520282349fe87548c",
      "0xe00e1fcd0d2d9dd0596ad23ce478173add265192",
      "0xd46F49dF4BBdac66AF2A0207EC2b32B26409536d",
      "0xd6d8327649918e5e8dacb4a159078701d23c66db",
      "0x7B08aACd16069674C1281F4d7254F974f96f1030",
      "0xA42733347414be5eb073a75F14e273063d717E97",
      "0xAAb84232ec02EC3c4610a71ceeE218a6A857d7C6",
      "0xDdfcDfd3d9Fe929FB8e412bdA6c3Fd8cED7209d2",
      "0x46C7BF2D34E751637c940e4337153c841E299725",
      "0xE0C2b758D41501C50E67a8664EbBfE2C55f947aC",
      "0x116FD045CAA3603d54776c44271bE88AF2df3708",
      "0x6B183BA7A8F0A6890841BBE609390988f6BFfF23",
      "0x5b765b82ea9094215eAf267b3719419510ddCCA2",
      "0xF9058403AFDbA545bD3c1174979689EF41F778Ea",
      "0x112CAB33A5cdD4144d0439F55430aEd6d766AC3D",
      "0xa8041B37C8868eFA1f8d6B2439D723663974Ce7d",
      "0xe76F6f491ec2E88702F4EC54e415b9980c2EBB51",
      "0x81a81043d92a08ba1c3e517666900981c699ba97",
      "0xAC54C85E69e1076eD39448736c04afa2717c7d00",
      "0x72b66a93725D6c350cA72E43e9d780e3819784a7",
      "0x1e954F1ea5F96709D7553110B10da20ea700130E",
      "0x4624185473Cb1322CB0B7079CdbD6EA321bEa585",
      "0x6D469BE4Dc8DA9aA7AF735D5E097A458dfeffb19",
      "0xC42d4b71F33Fd037c8DDA34Aa7BBb13bCa5d68c6",
      "0x2BAa414c2318230525DBcdDc105b945CaF95BA41",
      "0x3097d05f8b24B460916d2B32f9237A3eD242c3Aa",
      "0xFBb9847ed294476132453Cd420fEBB524F53Ff9c",
      "0x3015Ab3EB0f2B0498F32C9adeeb8Fd8E1192e695",
      "0xB894acf7AcF7dBf55a9080a027e1Fd114fe1c979",
      "0x7E6fb4B089bB294406CfD613c67B47523E9D2BF0",
      "0x298cC45154c670d1cB0918b2EDe65B1bE51A23e8",
      "0x54b6F3ffF1009da4fc6777d8C31B7BC896582D1e",
      "0xc5eBd769993800DD3CfC8ff2F9c12F9946cb1562",
      "0x4138F168419bd0F7dC60B63d38922F53c6a61c16",
      "0x34A4E89b243513337049f2320236619b2d4a208F",
      "0x6dbC5Ddf41b143E665ec37ee2aC0E01e994e7b8a",
      "0x2D9888a0a087612E2541881B9eD30B94Ce6999dD",
      "0x3beCfD89f7801a66Ce66C7eBeBbea4FC9693aA9E",
      "0x0bE8cdB1605B0fA156BB05C0E5a979f22ae4683c",
      "0x8a97f9512902Ab771B06B804094A0AF1d2c4608C",
      "0x59fAF4Ee296d6F96Bc9c1fD82118653Fd6AD34e6",
      "0xa2f534823C243910714c3cc9C053F9c59aC469ED",
      "0x4e856D380De30c25c6c697577a0e3c449301B48C",
      "0xd20494885E86528f7668691aF8b4B062E1f63cB3",
      "0xb7eC6Fa852017296eeF3Cef55578058663923C10",
      "0x09dbfb9dc7e1415e28990c45f9737b1b34d40404",
      "0x97A1B8d189dCb73911A723B9bD106416275C2313",
      "0x8a56D0772dd347f34e4CABa23D1d1040c838b5ec",
      "0xAB331d3a5B7458F2f783088d156578bAb57E7989",
      "0x61a64e2D9Dc590c3D664aC8E5870c3233b05422C",
      "0x570CB837eb10318AD957fE05b480Cf66aEF0C33B",
      "0xd5d7c5f7fc2caab930ae4ca5039ccf5efed328fe",
      "0x8A8D1b435b69Dbe55e535A874AD8c9A413472746",
      "0xF8720d50b3BdFE6003a86691618c3D765D9E29dF",
      "0x05A27E2a56A86549f063F209b682E5F32Be293F3",
      "0x1eA233B537CBBAeb488C852Ec19e0EA1F6543236",
      "0xD8778C3793dacCF53f29d804D0334d4643DB0eCE",
      "0xabe7920609c4f65eD5C650fCcD3e71186083F803",
      "0x0a7B4D67497BbC6908fAe3fF5544bf1b83F933A3",
      "0xd21F1e6804eFA13a3529acc674C060d668E08129",
      "0xd69F1fdc46c005521e415f5FCb02e99b20321845",
      "0xae33b1761a282d39B2B140445A4E5ceD758C40FD",
      "0xF954d4556bb6e04B00A2a3E556779A73502b6c7d",
      "0x53Eb7193bfE7b7a65f7D4a5e4Ba50eE40a956011",
      "0x7C7B29D5f13D52Ded7F35AC01bB22557773bd3D7",
      "0x496Ff1d4DA0DD4bcefBaf0C83Dc1BdB386Fd5734",
      "0x71843Be90E91f512A60f33886C8540521dE70013",
      "0x3c05FA820E52910Fb7A92DAc428Dbcc6958cF376",
      "0xfa858a3ed6af6f6a564af45698afe5a8ce7f5025",
      "0x403936F4af499bf984426E5a4e385BB66Aa5B8E2",
      "0xe438989A5d69Cb26603A7b7448Db52aC48e96b0c",
      "0x6a34b1a342bd4a4c5dc1b2ed03533615af056867",
      "0xb5d4866e879d7d9a7872e9d3951f7870cea5795c",
      "0x1187AcB903C97223B3a6041074eF151F3B6836EF",
      "0x876BFDA0e2c9a79aB54e2b0598FFcb27a8658463",
      "0xe90EDd464f13D1553046819905021EA34A033b45",
      "0xe6f6cF366BD0D6FcFD3bba40E84705D1b4cdbBEa",
      "0x21AF5bE546ba2D16990eA8c44819A7Ed5aFAFbDC",
      "0x303df209b4Fa54C0c482f6ccD4D8791138D56eB1",
      "0x721549d28D27341A4ca79A40f787F23848Daa541",
      "0x8e9Bec8Df3b5006f17eC2e782faa86Ccf68e65Fe",
      "0x0D83B2557aBEaBc1a138505c5323173705c8BD50",
      "0x33d03CA02FB57ff230C9E0c00a4C8DB197bA72ca",
      "0x2c3e17B04dE2d558A802510Ef5F29b2947c9C952",
      "0x56aDF1125741234b969d1c219e2F724367e0a4f2",
      "0x9DeaaA7bf54fD0E8bD735a88652DfaE0D2789742",
      "0x61FF857bdb8daA8Ea2937F009c6AAf8571a4e960",
      "bnb15hktw3mcavahy7symndz4x3p3jtpxy5pc0h2gx",
      "0x0a32583f7b91B8963Aa65E8Faa2E428C0B049Bf7",
      "0xA219222119751f5074333A00d45edD417970ED19",
      "0x418727f8770a5ae3f070b4165f694245cde59fe1",
      "TGUJNkQG8eyq2q5fnqpVPX6So6ALYN77R8",
      "0xac50F1dE5d281d9E5896676ed32560c051A7AfAD",
      "vjvgvvjvbxdfxcgvhjkuiytrewazsxdcfgvhb",
      "0x42f3beA4241b50f3541EC0DE82A5CEb23740c516",
      "0xF5283218819a4eedd4DBc9E858bD0e176d589c22",
      "0xbA750672E0BF5a21C2C333D3640857ca5Bf85028",
      "0x416D199FFF50904298C8C27E5D2fE0240Db8fe35",
      "0xBFE5554AE38Aa81fd675e44232031F90c401b8B1",
      "0x448FCa35c81168C4b191C7E81634C11F258C0Cb0",
      "0x0049753f7f815b88412f59a6816bBbBC3976b961",
      "0x2974F18adf795389c2E983e794Bd498bB000e4C5",
      "0x383b034C47D7578a17CD7D1e4ade7A78E9f877d8",
      "0xF9bBe2eaC37A9c966a3c35F20CFe69888bA69b84",
      "0x898E740e86D65033FD62746406bB22a73E671044",
      "0x6d211A947B9Fe355C6F01B825982fca7EF7e9f67",
      "0x03bc3673214Af28205062A09eC52197d71924B1E",
      "0xD493A5F35CD75DA4b48D1572e80C1C42f5e19BDA",
      "0x271D9B88273471397Eb8Af6b5C93f2C9CbcB1990",
      "0x5d18fcf2405b7abc490b58c4f1f3ca7ab2617e37",
      "0xD040370A079e59ebC1c46C9a504A470F3675dfC0",
      "0xAe72cbcA82D1De8ef750e6dBc5c412b4B787d842",
      "0xE3B1f4931d4548bBdccEEdEBF8Aa2813b63112a5",
      "0xa8a9F236737E56A05E64A33e541Dd06dBE902E4C",
      "0xF9ad1457F42C2DFb96Dd555fF76b7d0BDb078D4C",
      "0xf5D7e79849B9E93cDAb76D984F9ed63B8eAbeE26",
      "0xd006f7F1b61C7f62A940CF48E1f1E2f848E68409",
      "0x8A4C19E6Df96cd9644A8dfA7A1bD041560DeAbE4",
      "0x08602FFB1039a174acf9FB32442f228Ac52fC91b",
      "0x3206926a03318b82878027dcDA50409AC7C1d7d2",
      "0x52d8E20300c79b4d3a99808B41052883ca09cf86",
      "0x1444Acf95E12433e011C2f730af498315Db4abf6",
      "0x5dfb5767d0050cc305a97ecfe23f4a325f3a5f4d",
      "0xF70AdF657e7dDE4D3CA30f0889Ea1f29d7f18Ca9",
      "0x420a5ef3b8d70c6aa5460a3123258f3c6295189c",
      "0xBb84a67fcBC1e36032390df5e985e9140779f000",
      "0xB56f9D5eA2bE9838ebAD2c4f931E048400a97b36",
      "0x5B08d7D4C64d1796b9eB591Cd7ddD38feaEc63eD",
      "0xCa9EfBff3f6Ed3Dc35fED7E116C707c0Ed6dE28A",
      "0xeDE14d34bd01545fECd9b360Ddf114b3C6cfa392",
      "0x37BfDad4Dc4A47aFcaf535622b29cF1585bd6D6B",
      "0x296B8D62d9B6c652F9d8D3272B41186182ee5218",
      "0xe402A9Db43400610Dd2E36A2D8E1F05Ba9E67AaC",
      "0x32c03E80ad0ed627b3cf8d21E09B8951dFCFcB23",
      "0xbf85f9839f9F948be9793D63a850F12793E7861B",
      "0xEF92ccEe39f6b8E8E779B3877af01f80BB058875",
      "0x07B07F12B2F273EC7f6692A94Ef3D4f6885FFaE9",
      "0xd8d140c700b2dF2Adef0d364ab6B93f276D514E1",
      "0x2edcc372cA31a019D938893fD40D7A953Db54935",
      "0x87c30a244baB91Eb76aDe175Ac768E23D7f01A43",
      "0x399C254A5d6C0A8862BaEEB4A6dc8780242d7F30",
      "0x3B1080Bb7786Ab1a4050d088a1f1CEb2F469deEC",
      "0xC199ACf3428931a4B831E1C17e92a1e21c91738A",
      "0x1FDeAF9a61DF19070C798ED53cAf1008Bd9952b3",
      "0xA003471a32137731a1aF4d59d12B87b2D2B1169b",
      "0x91A0D420982147af1b2ACD86dcC94e3E4CEeC885",
      "0xb1eF63175AC31eE0C5E5E2BcB157F300cEAADfEf",
      "0xB81968573855EAD7583e2c0D88616190CE408C5A",
      "0x5Eed609d82857aF3932845470bB72E0B52009D31",
      "0xF2F2b17ecB4cE06B6a038756b3BFC647f5D0108f",
      "0x0b0d543a4c26154F2c460Cb49fcd09bE19F103f9",
      "0x6Cf1E40713A825A50d769B34BB5AC101eEAEf580",
      "0x9E6bfcdFb8896B465984fC0d638E84fB51c96688",
      "0x93eCb5e7521a3a5165efe7fB8d9c3D118822A049",
      "0x62Fd33700d3480ECE76e011f25Bb7c0cf5E9A1bd",
      "0xd7Ed5D8FB7aE1DAE6C0A5852A316a76AF13231A6",
      "0xcfe8F1098b92424c126D992654aA2270F1460589",
      "0x67033c9872A53b575557e4bB7f58A42701268bae",
      "0x032302cecd7fdb657e3d970f9cd781b7e6063189",
      "0x94783b63779383193B7EF2fB1C7cafB0E63c3C3F",
      "0x6Fb37DF413EfDa0aFd20b1d8CD51BD3EBFa5D4f4",
      "0x10A20286185838893CABdd35A48E6f68cc9E4485",
      "0x912cC20EFACC2a27B6B7601faF21E6A7B3843382",
      "0xc1556C9adE7B8b45D655a21b56eC481d51c3116F",
      "0xe52CC2e5BFE1109b68dDf32D5c823E93198dd626",
      "0xe549564D000Eaa8C5DEaE53E802d741350C6d477",
      "0xCf136BDaC12c2156bDC7D5EcEf259c19e60EA12f",
      "0xd820e2b117dFF0e3547E785406ED186dDAD615ad",
      "0x1103B3F359Ada5B7AeC879B8Ab29f8B4f0cc2686",
      "0x1b778b661d7319495784af0d3958c18e52814aaa",
      "0xd5bC174f9c2568312EA126F00430E7D2EA48f1A9",
      "0x7509f464925aEA152FDB276161e82a22779175C4",
      "0x6FDF89F80741eC74434a27aE76363BB670580a3B",
      "0x6671B1371102d791135A829fFaEA75A0b677ec6B",
      "0xD4f2cA0313b4f573B5128917EB9316e1EC924fC8",
      "0x3A98C0283C1D9aCC794F8F4fcC9F8Edb4a3a489C",
      "0x2ba389DDA6D8ed597d1b29d053077BbA711fEDF4",
      "0xAbe4f1971B3e6b19caC55195D3e0851fE1b9d3Cf",
      "0xc5A141C4706efc105EB04E487f8d2E986530FA52",
      "0x2a370522EDCFE254dCEa81bb9F3CF04252f2bEa9",
      "0x3A721a729183dBfeE89763f1CF38d1ceD00f4221",
      "0x971F08eeE53e187Cd12FA9ca9eb759586A19B4E0",
      "0xFde42f35161690026E6A41F676bE5046bFc57D86",
      "0xa13f9e25d5bfa707c2e7f204ab19a7fcc9332432",
      "0x81B2735701B91658fE0e80d6F8a9C0FB9A525A14",
      "0x67f03D85f0165072b6DE7aF63612b784353888c5",
      "0x38F2d58630fC51377cc2Ed486Cae60818191a356",
      "0x2F82404afdB66D2599737039Ed23C51203ceD402",
      "0x2bdd0D3e231a30229EE96eD1B6b8f3Ffa41688DE",
      "0x581c8eF97BBff3d0D89bA3d3350804D79e6Bd459",
      "0x7e0D21214f230BDDf0439768dAb94235746361c9",
      "0x4f692d8957b0B28B4090f5b3452C59f66EfD0Cdc",
      "0xbb40bFF8ec3AB3f89F5AbC267a7063D547205AD7",
      "0xcdc94ffbe2c37381505e5aa13c4c3def3eea7f90",
      "0xF13b15aeDD45cf68e14EEE0D56038dBfB7B97554",
      "0x59dE0B817667a4d47B07a6fBf814C463c6B64d66",
      "0xfBC35019ee0E5772c1A868D6fF43047212Ee6E4e",
      "0xa6d448906a0143a77e32c4f1da003d955b402d6f",
      "0xa5cb3F3967EE3FC1CeABe13ae5e98DFBd6904CB0",
      "0x16264Dd77298d52CB630DFF11fe5F5AaA7c203FE",
      "0x2dE839B944fE8e9AE797B87dec3D9c526eb4C194",
      "0x02C88F6EbeF4F3571DA1b7c401A2cde5624C39A3$10",
      "0xdAFC50f85929a4dF929e03f52a20B7758BA4abC4",
      "0xc91e7D608a3380bf70b33218c4D31fD64E0401a9",
      "0xdd6fD33C053Fe8Ad6d65d3Ef37E556054253581c",
      "0x0ae74aa5424c3600e63adff6f5a6ae883fc8fdfe",
      "0xFFf9D1337cA91cdF5Cdb47c73F60A56B5bCF4A72",
      "0x785370c04C4C58F0D3c6f0bC251AbD87bD33F501",
      "0x6220ba46aa42a5fB6447D55D8b10DAf84ed66cB3",
      "0xD51742Aa143Bb593132209273A5Aa6AE4324a14A",
      "0xcbb27dca9cf38ba12bead1ce3e06afc73ebc0c1a",
      "0x7726307B7Ffe6e4a555813E2821134817F4CDa9A",
      "0xe1517363B249A0dE859ab90222B32ebFD6a6E4D2",
      "0xC9f00E12079B87311Ea751D98181F4f283E2F370",
      "0xA0279F22D26A3f6bc4B7283519563EE6C0Eed35B",
      "0xE815f7237Bb988B11CF820DF43E3C7A226766F40",
      "0x9df5aa89e51b01Ca20131a4EFA6ea2eB3761167D",
      "0x6D0E5d4971724d2CEd4411160c53cf74fFcB87bE",
      "0x7c8b40d00525EE48091b426cB4b8c1392B7Ae5dd",
      "0xbb2E8E65F90b3c597fa62f9E5a32f4e9898CA674",
      "0x2005126010A164B07442a3F003e3E31dFefA9D0d",
      "0x3a873e0d6074a4a9165ad19a8ec058fe73c7c3b2",
      "0xcA84d040Ec5f3BcCFb8A641AA81055866C6eA2C3",
      "0x09148eeb09D2a29ec4826e4c9c8F97646675f12B",
      "0xE48756a7e843b225f588158C184CFE8A9CdAbAF",
      "0x34b6645bAbe642D9A4621BD9233d43461eEA3cF2",
      "0x926Eb7Be0D7B2baCab812d5334AA4a144fDdeAD3",
      "0xeCeF4C1075CCcFedbc875bc0Cf5261A510Db2e8B",
      "0xa266fc5458cd519718240d6cb0f530eda25c74b0",
      "0x3892E628DDdfF7db4a89aaC40DD892d82a4c6480",
      "0x850cB3EE47A7723880e1C9FFe4b15f9d53089ea3",
      "0xe80FA2792ab4Fa6F53800401C44589c38aa7eAcD",
      "0x499d740539e4D2b395B4EDcb8EA12f3DFDba4e7b",
      "0x034c3a386DCDCD8C2BF0648FE6B7Aa0E32683267",
      "0xF2Dd02eDB1a8Ef3222CB61652A76644731248C42",
      "0x622E01Ca6434042D30a2c94d1Ec14A4426126109",
      "0x1a62e137f50567cc16a09d738a9b9F607b7D43F6",
      "0x6706347961ca66dcdb8962d9b550d2fde95a4510",
      "0x61D7f4Dd8b5D8E9416fE8Fd81224171cAA32112b",
      "0xB79A09fe0220BC97b0B0C7c32839f5260c6E9B0B",
      "0x7857Ba3934e87CA0F853C1D392eC331e716647dd",
      "0x3fFf7d1b4Bb180354B7B0Fac5f980Db5c6D4adD4",
      "0x93dAFa793E24208f86D2cb14F1363d9Fea3e55A1",
      "0x762c9b1d30FD6C29f18c0D64DD7C5672d234f342",
      "0x608c9D20260cE33250E8D6BF4c19A90E3C4Da59c",
      "0xA1969297B9E73f96C014d823e8ac7AF0a9076681",
      "0x9A27163660859A7e4A700d544fa9A977D776466E",
      "0xb07834b98e6E55994dA091225f84D9bFac693B04",
      "0x77B5413801079E66baA434898677B11319a4289A",
      "0x480AF26ec771F54aFDe817AA0318BA7a5f8478EF",
      "0x2951D57Aa4592fD1A8feE70C1fe3658fEFf7612d",
      "0x4681bfcb9496BEecDccf637cE43832a439D852ab",
      "0x69A09e3bE0d044c34Cb97B2e62aC1622127e27B2",
      "0xB345b59e7E0Dcd095475f51c8fb74E1c76F965Ab",
      "0x1b6063259fd1c393dc2e788f67175039c203b646",
      "0x9528767BebE4ede37DaCda8Aa71100F4b623bFd9",
      "0xFb6f5095DFB87758C2d0D4a7cF3d52aB14c1125C",
      "0xd8460754CeB7f877B98190C5E93b8c7B78B04555",
      "0x61FE7e1aBF35f0058DF1FdCd8a2798C91089552b",
      "0x68a5b961E9B119C356D2deb4539bA8dBD3e39A37",
      "0x0170B2Df5438C1435C51F412fc157ED657fA47C4",
      "0x38031918b9965d21F5c5D4B21e834FF412Cf9874",
      "0xf7efd37ec73ff311766094369cce0340e49f32ba$2",
      "0x31E4Cb29d95539c3e8022871D887AA9669845b99",
      "0x54e623613afbaee0b0758e93dd1b1893db336445",
      "0x8d48548582E885A17213CcFb463580b05E91595D",
      "0xda031be38071c020abca39ef4ed4d8622e52e5dd",
      "0x92cc5e08cFD6Ebc7476ae121BF32Ddd5AA879140",
      "0x9081C29c239D8B7634Ef3c808053e6151FE5aA1a",
      "0xFd19F3FBAD103B60ac611fBc22fE9fEC941D602f",
      "0x503eA754A799dC5413613f7A3ea36E57Dc0076D6",
      "0xaeca81930331a689027daa45221b111cf7fa9e91",
      "0x72b6f346516a75feddee5fb942a3c9a3d6b3e89e",
      "0x5D1c167a9916611b9eD1DC37C5CC01384639C3CD",
      "0xFe4394c318eE91B27E099c58a3416BB66A4002B6",
      "0xbBbFd094836a8B122b341ff09296F0dcce616F1D",
      "0x78a0870F97F5f18b96aa212143B661Ab0c9042B8",
      "0xe5016A7776071BcC7ffE3D0AE1D6a6c4F8aDDD48",
      "0x65B484Be8D3f4B650B527973CcCDBD3e909379bb",
      "0x8BE2a227f27E377D01c8412F63a1a54DF500e5d4",
      "0x22302bA4E18060E13DB627649cBeE5fE0A3Bf8C0",
      "0x38E5F27eDd08DCed67cc4254B96aA4cF01E768F7",
      "0x7cD3Ddc9E8d547474ACF28A6f960559dBf98F015",
      "0xB7995D1d0F89EE15F49a6A835b51Ed680B7E4136",
      "0xb89B4B41386f6F7D2C4411A752cAE533Bb10774a",
      "0x18bae2347906e23f66ea655303c276b0000bc0dd",
      "0x11f3F9ddC117980003c185d5cD022243e3F3d7E9",
      "0x01Dd3136C42A5cB5836A5b9eB5bdFc69eAEAac7F",
      "0x3Bf24e7e9Da047E7Bc43639470F738948b01734B",
      "0x69C6C9cCEa2A83B22720e81aC4e3D4C5eAD760Da",
      "0xCc549f89A8143033D1BebA417f9722e907Fc9Bf5",
      "0xB30A646ded2227b2c1Eae3D2b26f6496DBE8ab68",
      "0x6eD6B89A0D40De859C86aBa46bdBfC59053385c8",
      "0xAC8042D48d746f4EeaD71ac0a5451e66eC02dCA7",
      "0xf9627df60451675785744287d19073531F9edd4e",
      "0x1F7f02aCfE9c7631083D43DEA69241364313BDc5",
      "0x1907fd0B65a481E63e8DbC0ac9E8B1cB1F05E869",
      "0xb22FfE9eba3E65ba39A7C813cdCEE97D25caD6Db",
      "0x222151a4F8C3659e7ECd54E74567538C50613D36",
      "0x75EA8B692503C1Cb3f13f77e050F039788209719",
      "0xff4c69d842c1CE7cfF24b0fbb9Fa930eC085Df63",
      "0xE1447e7d242CC36029Dd0eD168B07d4F62758D46",
      "0x191ab420290B71a2337022D6da2FB8C12d78a624",
      "0xC53d89cea253A541dD8436F2E247A6B15e711886",
      "0x8E79E31a40aDDa5b1529648a98Adc9787d0d6F2E",
      "0xe143D083019b00306A26E7db9521AA77A6ec1460",
      "0x84e213cb0773Db895155f72d240994673bfAdd86",
      "0xD208aFAF2BE85836605B9E0d8DC819ca22f3ba04",
      "0x3981d9572aC99A96B8DC8A9c49eBfeF2310bC1ad",
      "0xFA79953E59D1FB094425161aaDC77e92bEeD5D29",
      "0xB45c5c82eDFeA0A21ecB129B537E787f1AD2e3Fd",
      "0xecce52C5D696D6A42A46FB7DD27dd18E64886c6E",
      "0x71Affa1D00EDd952B090cd04C46d107e77E3F9f7",
      "0x73Cb1A93c905539df198EA22EBBeCbE390baF5e4",
      "0xB7F063D02357C8696A30E691Ef055ba98391C22a",
      "0x14fC4d77484eca360287e4378CE6e9F322a1D543",
      "0x3124c322f39156C13351D4A89380669686A7C1b2",
      "0x5db88B2B2963c55c0E9eD1A318BE51A9622e1cd4",
      "0x161ED220EA6De58dD8C8Ff95D710d678C8D55767",
      "0x0c3F885899B3b5Ac5A782a6bc2a5ca8e4333db1D",
      "0xa861a3B1E65795a3BCba7865e16d70d1437818f2",
      "0xdB71CD8910f7c54683C91646D7D8fB584ff1EC17",
      "0x09EC4ad1E4765F5850B457a16B72cD8421a9a88c",
      "0x15dEC77e132d6232B8BCF928618FB9C876F99A05",
      "0x55DFA8A8EE300f0Ec422556AF32D0B5eB238bf7b",
      "0x2231A5F42869B376c1017E190932DF24ED493976",
      "0x920a6E5B8Ba0BC71FEdFfB194fBa6793cFDBa21a",
      "0x5cFD11260ec62F35df5085e6958DA88db7a0d480",
      "0x632c4b2951a58248ff2bb4ff78f9784a64f08e6c",
      "TSuYSDowyXV6HCEwhzCTcsWE4XqbFWowSb",
      "0x82Ab9b26659De63E3ff085d87fB63fDcE2C04A71",
      "0x0faef7d4f3a1d20aa4700b4b79ea5dac8ad4838a",
      "0x024e126A7e7a572C74CEbdED6CCdb57e2685E531",
      "0xa572ceF41984A0808E945C83ddAe53449C71EA1E",
      "0xB2DE2eCAef63C2F097055124c294151F7372797e",
      "0x0797d8a09ddc8564FE3A5452fC1F08b446bff113",
      "0x1F5598CA63A2aA48505e04750B7195AAA54698A8",
      "0x2f62308DBc2A4e201124c13FB043734624559047",
      "0xcAc77769219Fef6809A301e0C70188B0980Cac03",
      "0x270E6E0488dbc406BBC28d05e2CdD6Dba4ef5F2E",
      "0xF1353fe4DA52EcB1fEDb806bAee3C3339907ec8f",
      "0x0448937C48E2FD2eCA82e7bb9CF61f42C2267bC8",
      "0xaabba4a17e9861ca9a5d6ea627c865ed2b40a070",
      "0x479b0414a6E0053b0dd3B97673013E746aaAF23B",
      "0x3575D390a92CE06f077bb7228721C22120f7500a",
      "0xc63410e2f1d0C3E2c370a78DdbFE4ea4583A73FB",
      "0x6B4192345573961a5A6ce7dAf8AdeE9b42A89eed",
      "0x93177cbDBebb2196b2D37Bb0902144EcCcA12663",
      "0x87ee76DBBA101E4cCa07D2982e5c6600b0bf3652",
      "0x88e7574b02D56016f97283D03ed43fEc215C265a",
      "0xd979B2833e68A8A948D50Ed3B2bbE496aC5c994E",
      "0x271b7552d94215236AA0Db734dF76925cFE95770",
      "0x66E9FDF022e524EbACE55338c8F4FF529535D3c3",
      "0x9FCc660856684c1d25ACE188a1DE1f8bbd753c58",
      "0x142d5983Aa168C8A43fc6431c0b2091Fb5300439",
      "0x283818d11344EeC132AefD88330EaF5d3cbD0824",
      "oxbooA38bd321F807DD51e1cDd0C604E71b4E7fB0f",
      "0x82dc8050aAeBd39466Db862765daC3212dF9Dd27",
      "0x4BE70e83D659b2027B3106CC28d538D136A07A05",
      "0xeC53C90fA3781b30E0CbFd9EE1bF885906E21488",
      "0xe21EAd0a13D83eF00A6d50AEdF89bb4919092b7A",
      "0x1Dac669E6fcA2332B0A03AfB6e0175C833Bcb1b2",
      "0x4D37a9a7d839155F61720D4Cd6BE45a05c4098A8",
      "0xd26EE0C03B8136aB423ea8aD50CD6A31D7fF6099",
      "0x4A3968bD5aea8b7A7D5980C555a75b21EEFDc04B",
      "Usdt 0xeC93A8F8D41B4199374C37e72Dd2Ed851B5B5265",
      "ronin:ba956e625df34c3e5aabcaf930d833bd284aec5d",
      "0xa8a468c8E1457968b80448C35894978E736E2Af4",
      "0xBF15A72bf57068236BaC6fE610873Cc2694A65fA",
      "0x817F366307Dc1Afe72f4eFcAf71fF9cE7F2a16e4",
      "0xF3BeB3cd8249b704f80ED40ffAae95361626b9d1",
      "0xb266F307F34FB37CE91024aF9c945bca1f854e2C",
      "0xEF479D8674DD483598dfb7b05CE5B3d77b0A74d6",
      "THj7rozAV9nEUdPknWqjzSdqSqBimw7b8e",
      "0x49495d13ba45368b387d50d77e4f620f223e5d07",
      "0x9b5d06ffA4E467b461B3B3325C2b61Fc810199f7",
      "0x16377FbC02DBaf3e272345D56A5Cc5f3726a1577",
      "0x80772Fa133591ad3CD58A81391697D45952d8ADd",
      "0x0dBb43Eac13C65EB951fF0469842603642CA583b",
      "0x90Fe53870C0b7F37896030c40Adc2FFD3dbA7Cb8",
      "0x240302408ccd650E5989df9Bb3161ACA0D2f659D",
      "0x8bE7C23a08c99889D1c19aDA10AD09bCEF0AE7C0",
      "0x516cdd6d830582c20915d4926b9238740d3516c0",
      "0x24b974e4e0AfaA4c5B41B3b68297CA9803f07d43",
      "0x4Fd2BFD8B783a6F42D90Ccca0D93C713718Ed767",
      "0x71A82e54e9E9FF473fB525E41Ae0d530dC4a26c6",
      "0x95abAAAeD0D8D86e5844D0881C6ECd06112Db052",
      "0x09389DD859Fa0f65c14Ba7E153FAC183bCf09062",
      "0x0b22E66f524A1B5f7Be50B3508aEfF37e28F72fa",
      "0xd6c6b62e518a71c3aA0Ac0e14C05aEd4898C5AF1",
      "0x6341ac120D582C9A68583C9956ad777b4bB6C3Ad",
      "0xcdA1B2d3Fe1a94F58637b89cE5D81EBBd0316DB0",
      "0x90a2745e3220cFF50c33bdDBdf6b98aAb845D9EE",
      "0x20233Dc14cFb0615B54341efEbea49908e83Fe9b",
      "0x8aa03d70B5d05620B6691c73a11180a7572563F9",
      "0x477f8E21860fB974ebe739CD2B5Ea896e35FB28e",
      "0xA56cd2D51C4E73466096Ad3185903A7894E32D58",
      "0xBb349dc1f5f000E166BEa8c2761986B166061AdA",
      "0xd50184C82151ba3e3D7f4fF7Ed6A932F33A672bF",
      "0x3DBC87b9138fB108A3a6357b68b390C118763033",
      "0xDc081B17d6a944FB5bFc2907Bfd714F379815A23",
      "0xf851F93C69dB9079e4060e3Dc403b6f1B91E8205",
      "0x257a72cdf29Ce18178b387e3Eac982D4e9bF85F6",
      "0x0F83Ce3Efe167A861d1fe6B7F9d5fB814a917AEF",
      "0x49B372E4962593e3Ef9FaE39806a7d5FafEab08f",
      "0xC5B56D758495bC2F8A9cAd963bEB8e994B43D77d",
      "0xC30c74dB27bb72Fb05555fC480Aa627B0BCCf610",
      "0xa821260B0e88962F6Ae046CD7F1f1b69440B3FeE",
      "0x9D839f2c525c601481333d608c5E769F6d55e9fF",
      "0xBD0249e0CC6C31d38455Ae97a89FC24392450612",
      "0x05bE863a732850EdA08b562d6e3aFBF27aBE5b10",
      "0xC61E4E38C0079Bd5dD69039d685b321A3BcfD945",
      "0xC08EBDC820973A6DC44840979b13B07106508dc5",
      "0x3f7E409e63a16eeB59B52e0b6e75EacC4CA4B5fd",
      "0x0CCDbb61DDFCfA05f11243e910A8D9eb412A9999",
      "0x7ba9262cdbab63e044e8d3f96e9bb087eb6b8a4a",
      "0x7761D016c3adab9A1B65Ba8B020fA1A2F54dabb4",
      "0x881C67D4127dF1dE2Ea01D5fF9AA392920B5aa7E",
      "0x32Bc3e87A615f4A1d1fBbaa937fD6a78d872A628",
      "0x53e5d3b9eE686E2B4AEfdA3Cb62f50529B89DB27",
      "0x75640D2C24Edf9b819E1CE29932001e60cdDf1bc",
      "0xc69FdF8fBD1de1eB3a390fb9a789F83E0806106B",
      "0x60435Cb19B14c6b5c20768f568661d57b5917C63",
      "0xFe4c977a8F08595f11e02C74Fb060572E1eC7905",
      "0x294211ceE3f29eFFa2FD29697baC5ECBe92494F2",
      "0xFD8251bFae0694B0832828D2F0387E762244B774",
      "0x8FF7bFe8b5F9ee9b4307A839B87365338803C9Ae",
      "0x7100A0Ee6F2dF64a4198Ce2f9B11614dA25a31A4",
      "0x433c6c9F4904C43b7aFa925e56548c8e8D629425",
      "0x8967686e247A66a71b264C47B42f20214EcaD0Cb",
      "0xcA00124f5B4A07E3A7a810a0e6ce76d72431eC9D",
      "0xF8A6A8A4F09b33E16f4b6b1AbC448E14feb395D2",
      "0xd9FAA17481706980633d5DA00a59B4c02028E54d",
      "0xB125355ecEA7e2DF2Eb537Bb6FE2a413851eE3d4",
      "0x1Ea985bb07BBAC4458b5E2501a7f71609F6d95e2",
      "0x8469BfB107B6094efE52Cd5232Ea67F209efD5Cb",
      "0x8927927d0C7d74e8196c70f75574e86ee87c3f18",
      "0xF7793Fb4862d5D9821d86024C98E5E8424623406",
      "0xEAea257363fA466dc99B7383c94743BFA44F3FB2",
      "0xD1C9F6B15948B284196cE5a46978E9b95db9EC5F",
      "0x59c1c07bc503995d22ab93ff53e5ce30af1f8e83",
      "0xD052e4dfbf114BC58E13829bD346d278ca4C304d",
      "0x0cD8454201328F16949a7747a8E83035f7B86772",
      "0x96c3A882Bb61a4ddAC8345EE2f2eC11A55f393c3",
      "0x29c049BF2a2F9b3c7D3B78DF315640068e3f7ed2",
      "0x01a724780d8EB59989DE9A302C5a061C4637Ec5a",
      "0x0AAb64f8ABbBe39DC1BB3242f307c1e807d646Fc",
      "0x3781fA70F604E65aFFB3233cd6A4F86809c00b3B",
      "0x20180D9A3B28E89e666e75c3cdB88b2Fb28D8164",
      "0x72462237923EfE74B9538D05B9eadA876A530aAe",
      "0x35e1cB9189E07B18E201785144a5Fa093a8283CD",
      "0xFC4DAcFF4a9c2A225159E86c9e81D199C86857b8",
      "0xdd7c0b23A46ADAFe889232889DeBeBBD23E592DD",
      "0x7073f6e6D16dA065dccc0Fd2E3D3780b30C95791",
      "0x63a8cac05cc30A4A17fFB138e82cE4E26eadAabD",
      "0x19cba6fbde8e8d4fb110c92bf58ccff01d654c32",
      "0x174E657a034e10012ca295Ea141430870A1DE761",
      "0x75Cb81F95487C89c29F9A9Cc664A54269704988C",
      "0x46d2F5A54db9118bab75CFca3D449EE909bb8134",
      "0xc2071CdFdf085Aa2E9D0120e7bfF8d86B273172B",
      "0x0472F38657c96F3940eF6EEB0b0669df823D0d03",
      "0x4e86164E3Fc0E1D37D0c9E9685638F6eEC53decD",
      "0x99443696c084c1E7e4006E701Fa65843F80a60F0",
      "0x1fFD890e1F8518187D2C50f8A914BB7e3a7E375f",
      "0xd61b38549c97F1D58d589E7E027FA23b303536a5",
      "0x1EC2C7Cb496Ef1b62dcC269ceF1f459f6F2F9631",
      "0xf6Cd2AA33029a8F3C413a76e9420b14fC232Ea20",
      "0xDda94FAFEF1491825aD30b2e767f016F7c36e15B",
      "0x74E1564380A80BF1858c81b19F745e22d4D04482",
      "0xFa4Ba851f7a8CA193Da14a576A981E42672F965c",
      "0xb3BB212611Ff384C2Cb851BE451441C13D5064e3",
      "0x2747F978C368712699E5a76c1f5816Ffda37494e",
      "0x1b1ADe4d7a9A3f098c1d550F23c11ebF6042C3be",
      "0x2e9365514CD14CBDe46AB9a5548DA7B47428eD67",
      "0x41BA6499ffB0947D235F8bA95948a0fb2e365b67",
      "0xAE1b95b21b03B4E628e18793CE13d08B3446405F",
      "0x1b2d294ab6ca449e34F946B86540cd342cF49100",
      "0xB3a1aCE38BF84135EB830BcA7C64c763Ec1EBFA4",
      "0x24bE439E99De7654B9f5ba84e1ae73E1E7B5b1Fd",
      "0x3da1a43ABdFF7c16bF653D1B628E948d3cbc756c",
      "0xeD15f54f531B11Ab714DB0625384Ee2Eff93B3E0",
      "0x11187FfF4BA0E60660bB29C2Ad0c097a028eFB89",
      "0x0a8a711f30f526b8d159cfbd6c91d36b2e98745f",
      "0xa9DEA463A87e249885b5e92457d6f3b9f4dD5442",
      "0xD7e40f689245a98cF99cfE6481169F448C75d709",
      "0x7a8A3Fb07e9E45179902C88e000fB1d284F6D288",
      "0x0d72635c2796B1525E9E1833A64201Cc276896BD",
      "0xF4447DF34D5a8ff3CB07e949075032C9C652bb2A",
      "0x1AFE1Bc10aE59C390c8036cCdD92448D198d49Ed",
      "0x1f2e687d7dd304A53B693166cf1Db333bBf89b62",
      "0x11162B3eF3109ce94e97D1d9Fa86b75Bb392D39C",
      "0xCa301C3cD83F1D37Eba879762134c848ceAdF90f",
      "0x9E8D37ba4ad43237570a88Ecc119b3Cb2991459a",
      "0x9CD316401dC06A8CB0752670719821f1bfC6f01b",
      "0x430A974fa22C522C994669e260c314a3B2649926",
      "0x7B028B9B480539B460E1604c564Bdf30878cddb0",
      "0xfE66A76fd60d020cc06A052341dC87b4732aC4A8",
      "0xf7A0EBf5ccf808552A87688223Cc02CE80e3c195",
      "0x2720B69270b037426A052E5875dccCe90D73a2eD",
      "0x5Df22360607F07638e765571843C97d0a2F149Bf",
      "0xE6830417182FD463082ED7d3a494bd45758A1d5D",
      "0x93271dc0fc442d4e436399620c3f019dd944a6ce",
      "0x7B4A89883099B314dd44d8FF25765Cf0369012c6",
      "0x76a02B5f48103DA3F83712767c75bF4f3A1EDb1d",
      "0xc1c0B802DbEDD8782a10a7e39c55D353E61bEd67",
      "0xbf3cc4329d65c9b04C11859e6C76C8FE00FEF5c6",
      "0x240804E817db29480267256CCCA22c0859DAAB60",
      "0x4F60f738237c60B78BCDCE1F3d9Df09e5A260a55",
      "0x95d5220A72f3E2Cfb9a499104c9411cb9980e0b5",
      "0xa4CD402ED51970088ED1AFA7F067Cd4Db7a202a9",
      "0xD106b3571A6Fdfd477AbcFD029122220F81421aB",
      "0x6e704dEf5e5246e29e7edd955e8616cc2B044ffe",
      "0x11F4d851D4Eed88d570b25965e8037d57867D112",
      "0x20E2B814267979ad63E145Aa8593EcbCf3335D1F",
      "0x8c8324098612072b3BE3559B189FCAA21894B7AC",
      "0x3877c04Bf053F8b53bf708AB157F9353f5aa89a3",
      "0x62D830E6796d601F19c60931Bd9731578f865186",
      "0xf258A3a0090187f8BeC7c2d84d29F7F689b8b8ab",
      "0xC2249Fa5b81E0158C8e7eD69a6C66F88C4Aa4d27",
      "0x22bEDF4C05875Cc5804a4C0624376448412Ad309",
      "0xd8b1B9D12833f938A5cFc7C75f50Da5F5079dB0C",
      "0xFB4dA00fdef583e45f91e21F58f61c3d4e35e165",
      "0x9205eb451Bf955Ae72e5e649220342E132A29956",
      "0x122fe5457E183A36aea7aEABFD97bd932901Ea690",
      "0x8740A46afaFcDE605957F2F1885F433b6a40335A",
      "0x43F5a6e4b2c4C2a65cf828eE87c569446dEcFFD8",
      "0x1fE4B4B469Be8f967fD74646073874E910FC2A15",
      "0x791c4f3ba3dd5a30aa4cdaf5bed6f4e72959bc5b",
      "7o3G2x7KT9NXFA3bA3gb9hGKXD7p5h8jB1z4oMjuNngA",
      "0x58f9f964F909d7dD5B38dd89430c6E7118f0dD60",
      "0x277d1f8aac783ac8625935c330f1f0096abee174",
      "0xc781c7952161255d15ec7ca97633c4af4710eb01",
      "0xDb8002cC72f04bF32a7F5ceb3a6f7a6AC0Ff0633",
      "0x60967551B63087171bD2aa1839BD3BB4a2EcD066",
      "0x7Aff95842D4Ad792fC1c21C925fa1DE3d70e1046",
      "0x14387b2ead3BA4f186c0a4e91b6820017FBc92a7",
      "0xa37B24eC7f4abE695492906c0F8b3BCa18FE74E2",
      "0x193b672EC271D5Cf02Fdb441e178846D40c20161",
      "0xF3b8f1b546CE9e80a2318b375D22fCe22880f5cF",
      "0xc513276e6aa87691d70970b41aeec5179798d9cf",
      "0x6D8d4c7a0Cb480E1DE27a0c160441c9541e105cC",
      "0x5Ce96Ef3433Bb682Bab58a5ef98Bb8F96FF828a3",
      "0x683B8894fDf80269212CAbd1A83d598D755809C2",
      "0x183596F0947A189d94477D149cac66a02BAB3251",
      "0x8467D8f166fE193fA59808d965aA81FeD36A8fB5",
      "0x840c55A9b69520625CD26BB1eb5CC177B293E9BB",
      "0x34eB4CA70FDBb005EC87aCfA70327701668b4588",
      "0x639310f41eD5Bd8FDAD95578f403eC3f87Ed231E",
      "0x436E4C1989e9238fce2cfEa851883e0b0Bc64854",
      "0xc14dfc82c5f598f71adc1e55a7a010bffc4b885e",
      "0x64F9A05bC066Ce1f0A6d9A91c230E8B7334bCD41",
      "0xFcc7648931E01f338936E58F731C6C26141A739f",
      "0x620d5051B0D8212D9B30493735eD35C3C6459608",
      "0x2c43ca2845cA32a494e59a1237E8118cc51F8f94",
      "0x909500265Eb63a68532bA5a96a523Ad84B4A9487",
      "0x8875E127A954541f5d4761889AAEc17d3260B7A7",
      "0x0C0b31E140d31882DD7969C7D72737961bC02af7",
      "0x0af0d4ebf7Be7C73Bec8b2a53a3Bc2B2167c3603",
      "0xef5f493Fb381ff561E920743Fe07b9142e9FF35B",
      "0x0e8d6f551C1AA282D7297603EF4f54584b0428b8",
      "0x0FDe2Dc422A9b6A48EA66F6f8550025939402c19",
      "0x02E6E453D7C5fCB99fef8cD0e48C14c7A9AF7Ed9",
      "0x9bd013F4e5F748815ea622Be2f83Fca7cDFfdA15",
      "0xe884cBa1215CFfB6603af45eC1189f7Ed2f35191",
      "0xA51DeEfa8EC14A6b48bA8bC2A153327720b6f9ed",
      "0xff02b8E37d16a9530E4877cC0aaFD93580a64BFe",
      "0xf93896c6dde3aed11c2ab86a389cadfb8468fc3e",
      "0xc5B0B3D584339b36e7D45Ff87fBE2e7e250bb561",
      "0xC4F01ed5228Ce53D10C6928C18E2E9CB98552878",
      "0x8c4e7CC7bED0f9a5fAB2896D1D58356b69FddD95",
      "0x264Ca9e2bb8ed340D01170CC39A1D184E40dfA9A",
      "0xB270F16Aad5c3476741218cf1fc47975F8bF1413",
      "0xcd6328E94e83B95f8d13adE10F411A46648ec642",
      "0x57b57Eb7552A6e72dBF5E552fCf263199D0fE606",
      "0x300Fb01eE00a268E3f9F345B5DA88a41a079bc38",
      "0xc3a431f410a907a546f5e435a7b4b36eed37afe7",
      "0x8d51edd8Cff2062D3008da0620916f925a3364aB",
      "0xBa63b574a3AE61C4062FFc2b7848a31d90fE4f5b",
      "0xED6D8Eea990114f517E45a675C0b0b839199278f",
      "0xf900b8d48170E1a549c889cd7AC9437Fa6D14109",
      "0x64f47Ffb1Bb3B853C3235e035eD9Bc16d1a08bE1",
      "0x46F7d2dEA574ee3e4E1D2e4d3e40C0bbc8e7D0b5",
      "0x6611D72B7b2EEEFe2ae9a89e49e7aeC520BF3b93",
      "0x3f34B938B794e8152062f09e2C45b7C43a6823Cc",
      "0x479d99d21B279711aAA7A269EfD61f8F909c61DC",
      "0x829ed3034b08830091516021a8Cf3821Ec2A6132",
      "0x030264F5aE34FD0B9E87EF301221118928aE0B83",
      "0xC5d314c923D0f56a1379A2e2D869aD87444D2d76",
      "0x77Fae1f1c35fd123744F3C2a1f34e5e8bD60837a",
      "0x4bA9fec39D8177e8517f0126becE98743E0bd414",
      "0x8d9A05B4EF6D280Ad2173e8f0CbEF0b9698Db3e9",
      "0x09098dc4BD03C6342031131C3005B0B7BC145360",
      "0xb61548128Cc0cd783FBD73C04d1590bD32DD2B84",
      "0xbf717753C065A1d6C3a481733ef208055A36a9f9",
      "0x68db74280e069599ADe50A34F97059357c60Ae15",
      "0xcD780397e153032790b48B4b7cBfA8F256AEcc2E",
      "0x0D47c9CaB4277484Da57559f3F89Ea9428E808f2",
      "0x996eF7Dee62905888212e60549f195cCdd5898b8",
      "0x988A99908f6304EB823432623147D457A06AFE6e",
      "0x517971431cecB82b51Fe763D7ABD2C2A145E061A",
      "0xd84538ae1d63EF30ED922c8A8ea15751213F0316",
      "0xC4f1aA110652A198156826d5bEF2d0f5a7590E64",
      "0x6d4e2793018B1454D6512FaBA6800b0843Ad95E3",
      "0x22F28F4daD08D65F126D266E29a5a7B240591ee9",
      "0xbD1D97B76BCC2bB296900d21e066A51899674ac2",
      "0x081088881dD1d594BeC3CFcC6da09b74d7d5E8Fd",
      "0x5f10F095709afd1351A85fF7B14639f800e7321C",
      "0xa8ec567d5384070cf1B52a79690fE8407aD18698",
      "0xDe54074b547F325b9eA169273ade267192ad88fB",
      "0x95f159fcaD1E5B6CAABa24B468C46736479c8129",
      "0x6ff74fd3377d30F25c8658BCF3DaBCfefD21c5e5",
      "0xa68FB14b4e4771beC6803780F6b9DeaE44fe2DBC",
      "0xe2332014B8cC2a25245d547B1Fdc2EE258B68047",
      "0x7c19505D7E9893A30486cD2EA03190fE3DAE543D",
      "0xEC0c1080908E5CAf28E23ca8A46233eC9BC01419",
      "0x6BaBB7D5A434f61A247EDC48B1cf802dE543C405",
      "0x1a57c5833Ed7C0fe0Ae1F465b6E06996e4edB441",
      "0x246462A3BA3E02454e915B4Ba745E460a915b5eF",
      "0x5F887FfD5daaaFAcf4982b545b100fb54d72A18D",
      "0x1aB171Da7209D14F16DA94F1515ba226bb694643",
      "0x262c2c3C2dcA73Fd4D366CC4681F1826979d820C",
      "0xAAb84232ec02EC3c4610a71ceeE218a6A857d7C6",
      "0xC77167863492dc81AFD6A5d16F551070640AE1e3",
      "0x5F887FfD5daaaFAcf4982b545b100fb54d72A18D",
      "0x21eBF6f6790cea59de1442A9401DEbb4deE94b98",
      "0x0AAb64f8ABbBe39DC1BB3242f307c1e807d646Fc",
      "0x772D81F65eF86C39765D07FC7Aa8258dE148c80f",
      "0xa88778f3fe9751b31a3a1b55b784e9dc10d2ef27",
      "0x993DFe4603679F83cCB2E8358785886FC34F84F2",
      "0x28DF348e1B3423E1a5b72C082b9B13691bE23aE6",
      "0x30A71706CD4542004634aA56E6E2C91ACAdbf5ec",
      "0x7B8056Fd3Ee73A7796074b505d5B43C9d11C2648",
      "0x0bE8cdB1605B0fA156BB05C0E5a979f22ae4683c",
      "0x71A82e54e9E9FF473fB525E41Ae0d530dC4a26c6",
      "0x01975a07D85f8Ec242e46241086889F2CfeD9390",
      "0xa0fa01a9324cC20936fa048C62abbde790175cCf",
      "0xd84538ae1d63EF30ED922c8A8ea15751213F0316",
      "0x988A99908f6304EB823432623147D457A06AFE6e",
      "0xaAFc1822fc89244E280b354fcA7e50A98cDC7b43",
      "0xD216Cdfb971A7b5CC2D5BD673e00b29AD636d0a0",
      "0x4Ee05d9BBD2dbf96D091CaD42797F6E1379d3EFE",
      "0xeCd6bD87edEdEbB13399f3384A7219dD5aee1761",
      "0x9F0D2D849924294c14701944eC188Ebe7B1AeE03",
      "0xe89A728BBBeefE92B51Bf7b8Dae5cB03cB866B81",
      "0xFBb9847ed294476132453Cd420fEBB524F53Ff9c",
      "0xE321f5fB1bF578ea546cF04f28e3AECfE192f9Ad",
      "0x0B47aC514E06eCCB62B99EACa8048dCD8677a473",
      "0xe296648B1AEE0D1B77C6d6e504534388bBd5C4B1",
      "0x1E0e8969aF9FD331400F57C1B138D9b484b33b2d",
      "0x8cD396B759Ee504aA929a5138E36da16bF9516a5",
      "0x8253f2b45c6a65a46592B42CDc331757Fcc4EEea",
      "0x2D9888a0a087612E2541881B9eD30B94Ce6999dD",
      "0xEc03C5c80De28CEF69eF5Ea19A8947ae0F87b40f",
      "0xBAE183b69B6a6980e2BB89db2c6e1708DEFED680",
      "0x3eB8fFcf7859C7e9d201989CD6098539e6aCE431",
      "0x64A0C43fdC577f1576377C322912D68569d4d332",
      "0x2d00ceB82345f9CA710BdfB4861D5d3f48943C8a",
      "0x8B135e0c0019dc6147dD7bCA3f94a54837A524B8",
      "0x5DC464763E90a0Ca380166A7a1B126b5c3d9051C",
      "0x72A994184428B4FEc928A463E77F1625b6B081CC",
      "0xFE8253207A8d3314a9228d2de90E2C361481CD65",
      "0x08E3c15c086A7C3b81C7B45F0900c4fA579C9494",
      "0x40bc4d3e6274b6E7771b496eA04e0da4e699c313",
      "0xcf177acF3A3B23d1bd6198ea63d5469C62cA9787",
      "0xB6a4Cd75000dEF9D43Efd55e8c34e61d1d32d6c1",
      "0x1a95966Be04A31a2dCaB748637b9cE0622A70EA5",
      "0x937D4B158dd35c99C47e5233b153Cc0E6E34433c",
      "0xE950fa717f18AeDe788476a6397054E05CD1bcc1",
      "0x28C25aF1426Aa95f56d951c754Ca85D49DFb6Cc3",
      "0xc78daD12a91641c518ca25BE3696614c471d7214",
      "0xeD311C55B32932f76193E665179Cece833F81f20",
      "0xA510Dd7Ebb22c17dBDb825b6bB3E5b90a0873428",
      "0x97A1B8d189dCb73911A723B9bD106416275C2313",
      "0x8a810DA3df4846616A08ef06472811FbAffcfaA8",
      "0x64F84787DDb0F4324e29b5B9B465Fa059ead871d",
      "0xdc059F5D81e85e66FD9e8AD2C8D64109FA2711e6",
      "0x81a81043d92a08ba1c3e517666900981c699ba97",
      "0x5fa563BD75f580d2d83930AEaFf6986595DA787c",
      "0x84bA3E98B01E7b76643b9f676dd8d8d32B8878CB",
      "0x88D9F7847A890bd5d05eBd92926B30AdF8C26566",
      "0x382EECb65CCE7346050Cf5FCc5aEf3B110036356",
      "0xF4f9416331fdAe4B8637f1CCcDa73e1EcD89d661",
      "0x6A7144c4FFE140354088Af97e667b4341AA5E4a7",
      "0x3e254074153312a536BC9826A446954c02c001d9",
      "0x863e36e30D1c0aEaDAE0B0A2756635814b34ACB8",
      "0x47fF553D6De0F109560D202d69fd8710eb1BfD18",
      "0xf3B5F28C199b566772b04f2C606B8bAb5d1a4Cef",
      "0xBAE9C5f6be119144dC9d0ba7ef663e118B354C54",
      "0x41959aD7385feA749B7Aa75A56c94F11F9245E95",
      "0xd39294103FA95F19916505A5DB1ACF53B66A475c",
      "0x29761a47a63dBFcD1008AEEa07163a3566035e9d",
      "0x3a18BE5160Ff7147D179BAe8E53Dc12a47466515",
      "0x2e4E264647d5895d56a0bA9ba884EeeEaa7C69fc",
      "0x24756c636B93F35f64f51cfaE29F6cDDD4d0bc17",
      "0x856399402062647994Ed961CE0049EBfD59c440B",
      "0xB00CdE67C1299027F2DcCC8B62FF67b71Fa68921",
      "0x8a571FC4eBb4F6f8E2224bA532fA1001d9Cb3b65",
      "0xD9cDdb1034B0f793EAAb2dB1093cb2B651502736",
      "0xBc1c00969a3ea084c68e582Cda4b249b13851254",
      "0xcd22b2b9e3658c51281Ac5E870B7EBe38c0576be",
      "0x0a043F40BB647DE5B3b88c45ab37D208159e0FBB",
      "0xbf976bF20582dB126801B2CbF9815BAB8b7A8e33",
      "0x2997d68A7498A72a3a13201A44099187b446205c",
      "0x0B7e9174035e1DAEfa2fcbaB68218E25C8999bF2",
      "0x0D7BF3e52be7CB21108bFc754e3a0aD74e09036F",
      "0x02577234C55AbDa2cBd15BD83F12513Ce41FF8aB",
      "0x9e298838e9169b820d998E86D505dece5239B06A",
      "0x5F8E6179a07e3d0DA72a0C278BE66F3CbC5ec322",
      "0xc69a9ec2f5B050440E9C42D44cA00B33De967C48",
      "0x8D9B2e1Da67638cB5c8DfC9535a045147D6cbB49",
      "0x2c10EC78C6BBB8D6D9a85Ab5A9859b08BE2bd7Bf",
      "0x7f3b2D74bF069b21f3D984D48ab534958B1c3bDA",
      "0x7F3BD648D5B1C07a0034Ef01Ca012fe8f2B70E62",
      "0x0F3671Fc0d77DCdd9B1a73414a9fE695C0dc6bb3",
      "0xAC3b4011635ba1959E46539001A1C335A9a8772f",
      "0xBc09716f78c97f9A07821d3AEc752512228Ea17D",
      "0xF4954112F2d419F93BbD29168485cF7d2E2a71bd",
      "0x557e34a2bd6e676f3ee750f5aafdc219b9200c60",
      "0x93e4F637d5bdcf6A3ae5D12954E370636C4BF820",
      "0x4Fd8a89c2C5626246D6288A8EB2E3190EC701Fea",
      "0x49959e465f207B31A3db103D52a232e613d4e225",
      "0x311Ec20214733501d7Fd0DaB59644Bb7e83269Bd",
      "0x225B88E222878825bCD50a30b5dE80c02B815F8f",
      "0xD938c265a249263A1bFe965786110eB2eBfd3eDd",
      "0x2af93113dd44e7ef41d3da190f126466e5df438e",
      "0x229Ce44B15D570D72f219fBa6185BB7dD73706a6",
      "0xaf0f1B6443FB2c398ad3D10C62CB7288675Ab88d",
      "0x84067F8B52270f5C1b8538Ad820867C085294e05",
      "0x851602675a67326C1dECbB3623379ef6A1dd427f",
      "0x404A63D30C7990Fcb2FEb71f5573d5Df3096cE4B",
      "0xaa98Fa936c1bf2fEa038Bfe2B6A96dAF507506a6",
      "0xb0033f2E86E1E03FA2F7b07fFfb3D56CCc6ca023",
      "0x8eC18ABf1B13B299A2c53F1C947F45FE2f5D3c07",
      "0x7aeF9842136689EfeEF33Df8c95a6bc673648Bce",
      "0xf8796c395D3100d5259a8ae4eacc7EEBB79BC4Ea",
      "0x13c26c6Ad4B01d2B724eb02d01377657DaBdD21c",
      "0xE19206Ca62Cb8a126CE03E99C6262B9F012BCB05",
      "0x4A3B574d4DED8b817a05E5173b8239Bb2D635d56",
      "0xbB86ea8321FFfBee16335D783459C6E3a970B228",
      "0xca5E4303806eb94A2B4e7381b243617b0ec85347",
      "0xCfC0bcaB1205ad0B9A3827eE24258Bb5c468CaaE",
      "0xCb88392bE64a08a8Acb5ABa868EB4C5657E7663C",
      "0xCd570DCda17a57d9205F1DBe170493370c3E8205",
      "0x13e6eE48Ed89AC46Fb44Aa850b288315C915F162",
      "0xf614dC10A6bC12967D728f85eBC904F1653FbD11",
      "0xdE582FD19B935c774Ea6961755940273C19C0990",
      "0xB1c38DF750F1c88e5AD9f417D82075933a059877",
      "0x1e3B1eFfa0459b820EFf8C92B157743ED7730333",
      "0x4106Dee76F97745B08F6e6FF7Eff0f7eb8d01825",
      "0x9CAE2af6Efe8a90f2b5D4a1eFA628B994038910a",
      "0x7Cd7a2e82bC13eEeB1579Cf7dEC7AB1baE45114E",
      "0x64D31F9cA0A9Eb108086FDE31819C47F3f03F773",
      "0x5Ab6E3f407C4C50D9d6F60F8fA2cc240C2aBc5A0",
      "0xdE1685EF74Ca52217Ca71C21E5a3C9B1360732de",
      "0x7790500be2d23B9f742418Ff4497A540d6Dc5806",
      "0x012885762659E51C17Dd1e0ba407C365f25D5983",
      "0x1F5e3a6C8A46809D8b74D11d61276D95838a0373",
      "0x965598855B83bac2381e2FbAc3ad7B97CE1C01bf",
      "0xde0cfA0732099ecD7Bb7Cb56130c6866ac11b951",
      "0x7a4968513D2cD67436Dea9f7bCd6d9899dD9bF52",
      "0xA177A461477Ffaac88870C4C74C2550a43A2F916",
      "0xA177A461477Ffaac88870C4C74C2550a43A2F916",
      "0x033cbBc3c1508e06A48B8d62f68845AaD7dcE959",
      "0x80DaCa50D9CB4c6ee107DA681e2998aFC689AE66",
      "0x89F9f1753F87fb6d0aefABE342b0F160dFA47891",
      "0x241397b28B13221653DFaDC2223c23f5ee2a98Ca",
      "0x599f7ac1Ff2Ae123221745820efDF896F3c125fB",
      "0xF4f9416331fdAe4B8637f1CCcDa73e1EcD89d661",
      "0x36c928D4f37086F0E00c9894c46768A5159D57b8",
      "0x4D757F3C1ef182e73752eB2950863CE43D9BD623",
      "0xE035534C83260e70D358234614cE3312f3C31e63",
      "0xC9895432258a4f011Aa57b87c71E5815c12C469d",
      "0xabe21e003E1a3677e3dE35F5a8CC87abB2478B59",
      "0x31c54c475dB01641cF24f2B78b0564425154a8a7",
      "0x694dfe4911565ea29232BcB56fFB4C19c15568BE",
      "0x163aDC0485bA132819e9fCa6e354da56d0f93f1b",
      "0xFD4C91F753969b761C064f0d492A914218887304",
      "0x1DB6A95e07F42798dE78f9d00736135971C0e98c",
    ],
  },
};

export const ALLOWED_NETWORKS = {
  FARMING: VALID_APP_NETWORK,
  STAKING: VALID_APP_NETWORK,
};

export const ICON_END_POINT = "https://raw.githubusercontent.com/ForwardProtocol/TokenList/main/icons/128/icon/";
export const COINGECKO_PRICE_END_POINT = "https://raw.githubusercontent.com/ForwardProtocol/TokenList/main/token-list.json";

//PRODUCTION === metria (place it next to id where currently it is yfdai-finance), check coingecko listing
// export const METR_TOKEN_COINGECKO_PRICE = "https://api.coingecko.com/api/v3/simple/price?ids=yfdai-finance&vs_currencies=USD";
// export const FORWARD_TOKEN_CSV_NAME = "yfdai-finance"; //PRODUCTION === metria

//production
export const METR_TOKEN_COINGECKO_PRICE = "https://api.coingecko.com/api/v3/simple/price?ids=metria&vs_currencies=USD";
export const FORWARD_TOKEN_CSV_NAME = "metria"; //PRODUCTION === forward

export const BUY_FORWARD_LINK = `https://pancakeswap.finance/swap?outputCurrency=0x886640149E31E1430FA74Cc39725431eb82ddFB2`; //address is added statically ,change it as per requirements

export const IS_DEVICE_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
