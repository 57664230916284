import React from "react";
import styles from "./DocumentationTable.module.css";
import { Table } from "reactstrap";
const DocumentationTable = ({ websiteUrl, whitePaperUrl }) => {
  return (
    <>
      <div className="col-md-4 col-12 px-2 ">
        <div className={styles.tableBorder + " card p-md-4 p-3 mt-0 h-auto"}>
          <Table>
            <thead>
              <tr>
                <th>Documentation</th>
              </tr>
            </thead>
            <tbody className="fs-6">
              {websiteUrl && (
                <tr>
                  <td>
                    <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
                      Project Website
                    </a>
                  </td>
                </tr>
              )}
              {whitePaperUrl && (
                <tr>
                  <td>
                    <a href={whitePaperUrl} target="_blank" rel="noopener noreferrer">
                      Whitepaper
                    </a>
                  </td>
                </tr>
              )}
              {!websiteUrl && !whitePaperUrl && (
                <tr>
                  <td>
                    <p className="text-center" style={{ marginBottom: "0px" }}>
                      -
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
export default DocumentationTable;
