import { utils } from "ethers";

import TokenContractAbi from "../abi/TokenAbi.json";

export const tokenAbiInterface = new utils.Interface(TokenContractAbi);

export const balanceOfContractCall = (tokenContractAddress, wallet) => ({
  abi: tokenAbiInterface,
  address: tokenContractAddress,
  method: "balanceOf",
  args: [wallet],
});

export const allowanceContractCall = (tokenContractAddress, walletAddress, contractAddress) => ({
  abi: tokenAbiInterface,
  address: tokenContractAddress,
  method: "allowance",
  args: [walletAddress, contractAddress],
});

export const approveAllowanceFunction = "approve";
