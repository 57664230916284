import React from "react";
import styles from "./LaunchpadDetailTable.module.css";
import { shortenAddress } from "@usedapp/core";
import { Table } from "reactstrap";

const LaunchpadDetailTable = ({ projectName, tokenSymbol, marketcap, SalePrice, tokenAddress, networkUrl }) => {
  var address;
  if (tokenAddress) {
    address = shortenAddress(tokenAddress);
  }

  return (
    <>
      <div className="col-md-8 col-12 px-2">
        <div className={styles.tableBorder + " card p-md-4 p-3 "}>
          <Table>
            <thead>
              <tr>
                <th className="w-50">Launchpad Detail</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{projectName}</td>
              </tr>
              <tr>
                <td>Blockchain</td>
                <td>Ethereum</td>
              </tr>
              <tr>
                <td>Token symbol</td>
                <td>{tokenSymbol}</td>
              </tr>
              {/* <tr>
                <td>Market Cap </td>
                <td>{marketcap}</td>
              </tr> */}
              <tr>
                <td>Purpose/Use</td>
                <td>Project Development, Project Expansion, Utility for Metria DeX & NFT Marketplace</td>
                {/* <td>
                  Means of payment, Store of Value, Unit of measurement, Investment asset, Cross Border Payments, Utility, Consumers, Governments, and
                  our Mobile App Marketplace.
                </td> */}
              </tr>
              {/* <tr>
                <td>Private Sale Price</td>
                <td>{privateSalePrice}</td>
              </tr> */}
              <tr>
                <td>Presale Price</td>
                <td>{SalePrice}</td>
              </tr>
              <tr>
                <td>Token Address</td>
                {tokenAddress ? (
                  <td>
                    <a href={networkUrl} target="_blank" rel="noopener noreferrer">
                      {address}
                      <i className="bi bi-box-arrow-up-right"></i>
                    </a>{" "}
                  </td>
                ) : (
                  <td>-</td>
                )}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
export default LaunchpadDetailTable;
