//this file is to maintain unique action types

export const SET_LOADER_VISIBILITY = "SET_LOADER_VISIBILITY";
export const SET_SIDEBAR_VISIBILITY = "SET_SIDEBAR_VISIBILITY";
export const SET_IS_WALLET_CONNECTED = "SET_IS_WALLET_CONNECTED";
export const SET_IS_DETAILPAGE_OPEN = "SET_IS_DETAILPAGE_OPEN";
export const SET_DAPP_TXN_ERROR_QUEUE = "SET_DAPP_TXN_ERROR_QUEUE";
export const SET_DAPP_TXN_SUCCESS_QUEUE = "SET_DAPP_TXN_SUCCESS_QUEUE";

export const SET_COINGECKO_URL = "SET_COINGECKO_URL";

export const SET_USER_DATA = "SET_USER_DATA";
