import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useEthers } from "@usedapp/core";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Sale.module.css";
//actions
// import { _setLoader } from "../../actions/loader-action";
//cards
// import WhiteListFormCard from "../cards/WhiteListFormCard";
import NetworkErrorCard from "../../components/cards/NetworkErrorCard";
import IntroCard from "../../components/cards/IntroCard";
// import PublicPrivateSaleCard from "../../components/cards/PublicPrivateSaleCard";

//components
import ProjectLogo from "../../components/ProjectLogo";
import ConnectWalletButton from "../../components/ConnectWalletButton";
import DocumentationTable from "../../components/Table/DocumentationTable";
import LaunchpadDetailTable from "../../components/Table/LaunchpadDetailTable";
import VestinScheduleTable from "../../components/Table/VestingScheduleTable";
//services
import { SALE_DETAILS, VESTING, SALE } from "../../App.Config";
//

import Logo from "../../assets/images/metria-logo.svg";
import VestingCard from "../../components/cards/VestingCard";

const Vesting = () => {
  const { chainId } = useEthers();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathID = location.pathname.split("/");
  const isWalletConnected = useSelector((state) => state.masterReducer.isWalletConnected);

  const details = SALE_DETAILS[chainId];
  const WhitelistedUser = details && details.WhitelistUsers;

  return (
    <>
      <div className=" row mx-auto mb-2 align-items-baseline justify-content-around">
        <div className="col-md-5 col-12 mb-3 px-2">
          <div className="p-4">
            <div className=" mb-4 px-0">
              <ProjectLogo image={Logo} altText="Project icon" />
            </div>
            <div className={styles.statusAndDetailPill + " d-flex m-1 justify-content-between"}>
              <span className="badge rounded-pill bg-warning w-50 text-wrap">{details?.TokenName}</span>
            </div>
            <hr className={styles.hrTag} />
            <div className="row">
              <div className="col pt-2 pb-2">
                <p className={styles.fontOfTokenDetail} style={{ fontSize: "1rem", color: "#000" }}>
                  {details?.description}
                </p>
                <p>Note:{details?.note}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 d-sm-block">
                <ConnectWalletButton
                  showConnectedAddress={true}
                  style={{
                    background: "linear-gradient(27deg, #b673e3 12%, #716cdc 75%)",
                    borderRadius: "30px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* {chainId === details?.Network && value(launchpad).launchpadStatus === COMING_SOON && (
          <div className="col-md-7 col-12 px-2">
            <WaitingPeriodCard status={value(launchpad).launchpadStatusDiscription} />
          </div>
        )} */}
        {/* {isWalletConnected &&
          chainId === value(launchpad).Network &&
          [WAITING_FOR_PRIVATE_SALE, WAITING_FOR_PUBLIC_SALE, WAITING_FOR_VESTING].includes(value(launchpad).launchpadStatus) && (
            <div className="col-md-7 col-12 px-2">
              <WaitingPeriodCard status={value(launchpad).launchpadStatusDiscription} />
            </div>
          )} */}
        {/* {chainId === value(launchpad).Network && value(launchpad).launchpadStatus === COMPLETED && (
          <div className="col-md-7 col-12 px-2">
            <IntroCard status={value(launchpad).launchpadStatusDiscription} />
          </div>
        )} */}
        {isWalletConnected && chainId !== details?.Network && (
          <div className="col-md-7 col-12 px-2">
            <NetworkErrorCard NetworkName={details?.NetworkName} />
          </div>
        )}
        {/* {(!isWalletConnected || isWalletConnected) &&
          [COMING_SOON, COMPLETED].includes(value(launchpad).launchpadStatus) &&
          chainId !== value(launchpad).Network && (
            <div className="col-md-7 col-12 px-2">
              <NetworkErrorCard NetworkName={value(launchpad).NetworkName} />
            </div>
          )} */}
        {/* {isWalletConnected && chainId === details?.Network && details?.ProjectStatus === SALE && (
          <div className="col-md-6 px-2 pt-0 col-12">
            <PublicPrivateSaleCard
              sale={"Sale"}
              StakingTokenAddress={details?.DepositTokenAddress}
              StakingTokenSymbol={details?.DepositTokenSymbol}
              StakingTokenDecimal={details?.DepositTokenDecimal}
              saleAddress={details?.SaleAddress}
              tokenSymbol={details?.TokenSymbol}
              onChainWhitelisting={details?.OnChainWhiteList}
              offChainWhiteListing={details?.OffChainWhiteList}
              WhitelistedUser={WhitelistedUser}
              OnlyWhiteListed={details?.OnlyWhiteListed}
            />
          </div>
        )} */}
        {isWalletConnected && chainId === details?.Network && details?.ProjectStatus === VESTING && (
          <div className="col-md-5 col-lg-5 col-11 mx-3">
            <div className="row">
              <VestingCard
                VestingContract={details?.VestingAddress}
                onChainWhitelisting={details?.OnChainWhiteList}
                offChainWhiteListing={details?.OffChainWhiteList}
                OnlyWhiteListed={details?.OnlyWhiteListed}
                WhitelistedUser={WhitelistedUser}
                tokenSymbol={details?.TokenSymbol}
              />
            </div>
          </div>
        )}

        {/* {isWalletConnected &&
          chainId === value(launchpad).Network &&
          [PRIVATE_WHITELISTING, PUBLIC_WHITELISTING].includes(value(launchpad).launchpadStatus) && (
            <div className="col-md-7 col-12 px-2">
              <WhiteListFormCard Launchpad_Id={value(launchpad).Id} saleStatus={value(launchpad).saleStatus} />
            </div>
          )} */}

        {!isWalletConnected && (
          <div className="col-md-7 col-12 px-2">
            <IntroCard status={"Wallet not connected"} />
          </div>
        )}
      </div>

      <div className={" row mx-auto mt-3 mb-2"}>
        <DocumentationTable websiteUrl={details?.WebsiteUrl} whitePaperUrl={details?.WhitepaperUrl} />
        <LaunchpadDetailTable
          projectName={details?.ProjectName}
          tokenSymbol={details?.TokenSymbol}
          marketcap={details?.MarketCap}
          SalePrice={details?.SalePrice}
          tokenAddress={details?.TokenAddress}
          networkUrl={details?.TokenNetworkURL}
        />
        <div className="col-4"></div>
        <VestinScheduleTable SalePeriod={details?.SalePeriod} />
      </div>
    </>
  );
};

export default Vesting;
