import { utils } from "ethers";

import VestingAbi from "../abi/Vesting.json";
import { tokenAbiInterface } from "./TokenContract";
export const vestingAbiInterface = new utils.Interface(VestingAbi);

export const vestedAmountContractCall = (contractAddress, walletAddress) => ({
  abi: vestingAbiInterface,
  address: contractAddress,
  method: "vestedAmount",
  args: [walletAddress],
});
export const totalDrawnContractCall = (contractAddress, walletAddress) => ({
  abi: vestingAbiInterface,
  address: contractAddress,
  method: "totalDrawn",
  args: [walletAddress],
});
export const drawDownAmountContractCall = (contractAddress, walletAddress) => ({
  abi: vestingAbiInterface,
  address: contractAddress,
  method: "availableDrawDownAmount",
  args: [walletAddress],
});
export const getStartTime = (contractAddress) => ({
  abi: vestingAbiInterface,
  address: contractAddress,
  method: "start",
});
export const cliffDuration = (contractAddress) => ({
  abi: vestingAbiInterface,
  address: contractAddress,
  method: "cliffDuration",
});
export const getTokenAddress = (contractAddress) => ({
  abi: vestingAbiInterface,
  address: contractAddress,
  method: "token",
});
export const displayTokenSymbol = (tokenAddress) => ({
  abi: tokenAbiInterface,
  address: tokenAddress,
  method: "symbol",
});
export const claimToken = "drawDown";
