import React from "react";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import styles from "./DashboardLayout.module.css";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { IS_DEVICE_MOBILE } from "../../../App.Config";
import { setSidebarView } from "../../../actions/master-actions";

const DashboardLayout = (props) => {
  const dispatch = useDispatch();
  const { isSidebarOpen: sidebar } = useSelector((state) => state.masterReducer);

  const handleBodyClick = () => {
    if (IS_DEVICE_MOBILE && sidebar) {
      dispatch(setSidebarView(false));
    }
  };

  return (
    <Container fluid className="p-0 m-0">
      <Navbar />
      <div className={styles.bodyContainer}>
        <Sidebar />
        <div onClick={handleBodyClick} className={`${sidebar ? styles.sidebarOpenView : styles.sidebarCloseView}`}>
          {props.children}
        </div>
      </div>
    </Container>
  );
};

export default DashboardLayout;
