import React from "react";
import { DEFAULT_NETWORK_ERROR } from "../../App.Config";
import styles from "./NetworkError.module.css";

const NetworkError = ({ customError }) => {
  return (
    <>
      <div className={styles.errorContainer}>
        <h5>{customError ? customError : DEFAULT_NETWORK_ERROR}</h5>
        {/* <img src={icon} style={{ maxWidth: 400 }} /> */}
      </div>
    </>
  );
};

export default NetworkError;
