import React from "react";

import styles from "./ProjectLogo.module.css";

const ProjectLogo = ({ image, altText }) => {
  return (
    <>
      <div className={styles.tokenWrapper + " d-flex justify-content-center"}>
        <img className={styles.icon} src={image} alt={altText} />
      </div>
    </>
  );
};

export default ProjectLogo;
