import React from "react";
import styles from "./VestingScheduleTable.module.css";
import { Table } from "reactstrap";
const VestingScheduleTable = ({ SalePeriod }) => {
  const dateAndTimeFormation = (dateAndTime) => {
    return `${new Date(dateAndTime).toDateString()}, ${new Date(dateAndTime).toLocaleTimeString()}`;
  };

  return (
    <>
      <div className="col-md-8 col-12 px-2 ">
        <div className={" card p-md-4 p-3 "}>
          <Table>
            <thead>
              <tr>
                <th className="w-50">Vesting Schedule</th>
                <th></th>
              </tr>
            </thead>
          </Table>
          <p> 100% token allocation within 24hr of dex listing</p>
        </div>
      </div>
    </>
  );
};
export default VestingScheduleTable;
