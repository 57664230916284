import React, { useState, useEffect } from "react";
import { useEthers, useContractCalls, useContractFunction } from "@usedapp/core";
import { utils, Contract } from "ethers";
import { NavLink } from "react-router-dom";

import styles from "./VestingCard.module.css";

import {
  vestedAmountContractCall,
  totalDrawnContractCall,
  drawDownAmountContractCall,
  claimToken,
  vestingAbiInterface,
  displayTokenSymbol,
  getTokenAddress,
  cliffDuration,
  getStartTime,
} from "../../../views/Vesting/service/VestingContract";
import { toast } from "react-toastify";
import { setLoaderVisibility } from "../../../actions/master-actions";
import { useDispatch } from "react-redux";
// import { useUtilContractFunction } from "../../../../hooks/useDappUtility";
// import { BASE_URL } from "../../../../App.Config";
// import { getTimeRemaining } from "../../../../utils/date-io";

const VestingCard = ({
  VestingContract,
  Launchpad_Id,
  CheckWhitelist,
  tokenSymbol: TokenSymbol,
  onChainWhitelisting,
  offChainWhiteListing,
  OnlyWhiteListed,
  WhitelistedUser,
}) => {
  const { account } = useEthers();
  const [claimable, setClaimable] = useState();
  const [claimedToDate, setClaimedToDate] = useState();
  const [totalPurchase, setTotalPurchase] = useState();
  const [pending, setPending] = useState();
  const [currentStatus, setCurrentStatus] = useState(true);
  const [cliffTime, setCliffTime] = useState();
  const [startTime, setStartTime] = useState();
  const [isWhitelisted, setIsWhitelisted] = useState();
  const [isOffChainListed, setIsOffchainListed] = useState();
  const [isOnchainListed, setIsOnchainListed] = useState();

  const dispatch = useDispatch();
  const isCurrentTimeGreater = () => {
    return cliffTime && startTime && cliffTime + startTime < new Date().getTime();
  };

  if (VestingContract !== undefined) {
    var vestingContract = VestingContract && new Contract(VestingContract, vestingAbiInterface);
  }

  const [vestedAmountCall, totalDrawnCall, drawDownAmountCall, tokenSymbolCall, cliffDurationCall, startTimeCall] = useContractCalls([
    vestedAmountContractCall(VestingContract, account),
    totalDrawnContractCall(VestingContract, account),
    drawDownAmountContractCall(VestingContract, account),
    getTokenAddress(VestingContract),
    cliffDuration(VestingContract),
    getStartTime(VestingContract),
  ]);

  // we are using strapi token symbol to set it in UI
  // const [TokenSymbol] = useContractCalls([displayTokenSymbol(tokenSymbolCall && tokenSymbolCall[0])]);

  useEffect(() => {
    setTotalPurchase(vestedAmountCall ? utils.formatUnits(vestedAmountCall[0]._hex, 18) : 0.0);
    setClaimedToDate(totalDrawnCall ? utils.formatUnits(totalDrawnCall[0]._hex, 18) : 0.0);
    setClaimable(drawDownAmountCall ? utils.formatUnits(drawDownAmountCall[0]._hex, 18) : 0.0);
    setPending(totalPurchase - claimedToDate);
    setCliffTime(cliffDurationCall ? parseInt(cliffDurationCall) : 0);
    setStartTime(startTimeCall ? parseInt(startTimeCall) : 0);
  }, [vestedAmountCall, totalDrawnCall, drawDownAmountCall, totalPurchase, claimedToDate]);

  // const claim = useUtilContractFunction(vestingContract, claimToken);
  const { state: claimState, send: claim } = useContractFunction(vestingContract, claimToken);
  const purchaseToken = () => {
    claim();
  };

  // const checkUserWhitelisted = () => {
  //   const query = qs.stringify(
  //     {
  //       filters: {
  //         UserAddress: {
  //           $eq: `${account}`,
  //         },
  //         LaunchpadId: {
  //           $eq: `${Launchpad_Id}`,
  //         },
  //       },
  //     },
  //     {
  //       encodeValuesOnly: true,
  //     }
  //   );

  //   fetch(BASE_URL + `/api/whitelists?${query}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const res = Object.values(data)[0];
  //       setCurrentStatus(res.length > 0);
  //     })
  //     .catch((e) => console.log(e));
  // };

  useEffect(() => {
    setIsOffchainListed(WhitelistedUser.map((el) => el.toLowerCase()).includes(account.toLowerCase()));
  }, [account]);

  const isWhitelistedUser = (onChainWhitelisting, isOnchainListed, isOffChainListed) => {
    return onChainWhitelisting ? isOnchainListed : isOffChainListed;
  };
  useEffect(() => {
    setIsWhitelisted(isWhitelistedUser(onChainWhitelisting, isOnchainListed, isOffChainListed));
  }, [onChainWhitelisting, isOnchainListed, isOffChainListed]);

  useEffect(() => {
    // checkUserWhitelisted();
  }, [account]);

  useEffect(() => {
    if (claimState.status === "Mining") {
      dispatch(setLoaderVisibility(true));
    } else if (claimState.status === "Exception") {
      dispatch(setLoaderVisibility(false));
      toast.error(claimState.errorMessage);
    } else if (claimState.status === "Success") {
      dispatch(setLoaderVisibility(false));
      toast.success("Transaction is success.");
    } else if (claimState.status === "Failed") {
      dispatch(setLoaderVisibility(false));
    } else if (claimState.status === "None") {
      dispatch(setLoaderVisibility(false));
    }
  }, [claimState]);

  // const timeToClaim = startTime ? getTimeRemaining(new Date(startTime * 1000)) : null;
  // const timeToClaimText =
  //   timeToClaim &&
  //   (timeToClaim.days < 0 ? Math.abs(timeToClaim.days) + "d " : "") +
  //     (timeToClaim.hours < 0 ? Math.abs(timeToClaim.hours) + "h " : "") +
  //     (timeToClaim.minutes < 0 ? Math.abs(timeToClaim.minutes) + "m" : "");

  return (
    <>
      {((OnlyWhiteListed === true && isWhitelisted === true) || OnlyWhiteListed === false) && (
        <div className={styles.statusCard + " card"}>
          <div className="container p-md-auto p-0">
            <div className="card-body span-1">
              <div className="row mt-2 mb-2">
                <div className="col-12">
                  <h4>Vesting </h4>
                  {/* <div>{timeToClaimText ? timeToClaimText + " to TGE." : ""}</div> */}
                  <hr />
                </div>
              </div>
              <div className="mb-2 p-1">
                <div className="row justify-content-between align-items-center text-justify fs-6 p-1">
                  <div className="col-md-5 col-sm-6 col-12 mb-md-0 mb-3">
                    <h6>CLAIMED TILL NOW</h6>
                    <span>
                      {parseFloat(claimedToDate).toFixed(2)} &nbsp; {TokenSymbol}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-6 col-12 mb-md-0 mb-3">
                    <h6>TOTAL PURCHASED</h6>
                    <span>
                      {parseFloat(totalPurchase).toFixed(2)} &nbsp; {TokenSymbol}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="row justify-content-between align-items-center mt-3 p-1">
                  <div className="col-md-4 col-lg-4 col-12">
                    <h6>CLAIMABLE</h6>
                    {isCurrentTimeGreater() ? (
                      <span>
                        {parseFloat(claimable).toFixed(2)} &nbsp;
                        {TokenSymbol}
                      </span>
                    ) : (
                      0.0
                    )}
                  </div>
                  <div className={styles.registerButton + " col-md-6 col-lg-6 col-12 mt-md-0 mt-2"}>
                    <button
                      className={styles.claimBtn + " mb-1"}
                      onClick={purchaseToken}
                      style={{ background: "linear-gradient(342deg, #b673e3 12%, #716cdc 75%)", color: "white" }}
                    >
                      CLAIM
                    </button>
                    {/* <NavLink to="/staking">
                      <button
                        className={styles.jumpToStaking}
                        style={{ background: "linear-gradient(342deg, #b673e3 12%, #716cdc 75%)", color: "white" }}
                      >
                        STAKE
                      </button>
                    </NavLink> */}
                    <span className={styles.instruction}>Please take gas fee in consideration</span>
                  </div>
                </div>
                <hr />
                <div className="row justify-content-between align-items-center mt-2 p-1">
                  <div className="col-md-4 col-lg-4 col-12 mb-2">
                    <h6>PENDING</h6>
                    <span>
                      {parseFloat(pending).toFixed(2)} {TokenSymbol}
                    </span>
                  </div>
                  <div className=" col-md-6 col-lg-6 col-12 mb-2">
                    <span className={styles.instruction}>Token will be released as per vesting schedule</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isWhitelisted === false && OnlyWhiteListed && (
        <div className={styles.statusCard + " card"}>
          <div className="container">
            <div className="card-body p-1">
              <div className="row">
                <div className="col-12">
                  <h5 className="text-center mt-3 mb-3">Account not allowed </h5>
                </div>
                <hr />
                <div className="col-12 text-center mb-3">
                  <h1 style={{ fontSize: "5rem" }}>
                    <i className="bi bi-person-x"></i>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VestingCard;
